import {Patterns} from './../../../../../helpers/helper';
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CountryService} from '../../../../../services/country.service';
import {UserService} from '../../../../../services/user.service';

@Component({
  selector: 'app-add-edit-address-form',
  templateUrl: './add-edit-address-form.component.html',
  styleUrls: ['./add-edit-address-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditAddressFormComponent implements OnInit {
  @Input() editableAddress: any;
  @Input() country: any;
  @Output() onCancelAddEditAddress = new EventEmitter<number>();
  @Output() onUpdateAddressList = new EventEmitter<any>();

  addEditAddressForm: FormGroup;
  postalCodeMask: string;
  submitted = false;

  constructor(private countryService: CountryService, private fb: FormBuilder, private userService: UserService) {
  }

  ngOnInit() {
    this.buildForm();
    this.postalCodeMask = this.country.value === 2 ? Patterns.postalCode.mask.canada : Patterns.postalCode.mask.usa;
  }

  /**
   * Build new customer checkout model driven form
   */
  buildForm(): void {
    this.addEditAddressForm = this.fb.group(
      {
        'addressName': ['', [Validators.required]],
        'address1': ['', [Validators.required]],
        'address2': [''],
        'postalCode': ['', [Validators.required, Validators.pattern(this.getPatternForPostalCode())]],
        'city': ['', [Validators.required]],
        'state': ['', [Validators.required]],
        'countryId': [this.country.displayName, [Validators.required]],
        'isDefaultBillingAddress': [false],
        'isDefaultShippingAddress': [false],
        'addressId': [0]
      }
    );
    // this.addEditAddressForm.controls['countryId'].setValue(this.country.label);
    this.setAddressDetails();
    this.addEditAddressForm.valueChanges.subscribe(console.log);
  }

  getPatternForPostalCode(): RegExp {
    return this.country.value === 2 ? Patterns.postalCode.pattern.canada : Patterns.postalCode.pattern.usa;
  }

  /**
   * Emit cancel adding or editing event
   */
  cancelAddEditAddress() {
    this.onCancelAddEditAddress.emit(null);
  }

  /**
   * Return boolean is invalid passed form control or not
   * @param controlName {string}
   */
  controlIsNotValid(controlName: string): boolean {
    return this.addEditAddressForm.controls[controlName].invalid;
  }

  /**
   * Update exiat or adding new address
   */
  onSubmit(): void {
    this.submitted = true;
    const body = JSON.parse(JSON.stringify(this.addEditAddressForm.value));
    body['countryId'] = this.country.value;
    if (this.addEditAddressForm.valid && this.addEditAddressForm.dirty) {
      this.onUpdateAddressList.emit(body);
    }
  }

  // Fill out the form to editing address
  setAddressDetails() {
    if (this.editableAddress) {
      this.addEditAddressForm.controls['addressId'].setValue(this.editableAddress.addressId);
      for (const key in this.editableAddress) {
        if (key !== 'addressId' &&
          key !== 'countryId') {
          this.addEditAddressForm.controls[key].setValue(this.editableAddress[key]);
        }
      }
    }
  }

  /**
   * Show validation error when form is invalid and was submitted or is dirty
   * @param controlName {string}
   */
  showError(controlName: string) {
    return this.addEditAddressForm.controls[controlName].invalid &&
      this.addEditAddressForm.controls[controlName].dirty ||
      this.addEditAddressForm.controls[controlName].invalid && this.submitted;
  }

}
