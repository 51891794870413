import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stateName'
})

export class StateNamePipe implements PipeTransform {
  transform(stateCode: string, states: any): string {
    if (states.find(item => item.value === stateCode)) {
      return states.find(item => item.value === stateCode)['label'];
    }
  }
}
