export const Patterns = {
  email: /^(\S+)@(\S+)\.(\S{2})/,
  phone: {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    pattern: /\(\d{3}\)\s\d{3}-\d{4}/
  },
  postalCode: {
    mask: {
      usa: '00000-9999',
      canada: 'S0S 0S0'
    },
    pattern: {
      usa: /^\d{5}(-\d{4})?$/,
      canada: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/
    },
  },
  taxIdForSoleProprietorship: {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    pattern: /\d{3}-\d{2}-\d{4}/
  },
  taxIdForAnotherProprietorship: {
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    pattern: /\d{2}-\d{7}/
  }
};
