import {Component} from '@angular/core';

@Component({
  selector: 'app-cvv-help',
  templateUrl: './cvv-help.component.html',
  styleUrls: ['./cvv-help.component.css']
})
export class CvvHelpComponent {

  constructor() {
  }
}
