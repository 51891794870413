import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {WizardHelperService} from '../../../../../../services/wizard-helper.service';
import {ArpOrderService} from '../../../../../../services/arp-order.service';
import {Product} from '../../../../../../classes/arp-order';
import {Message} from 'primeng/primeng';

@Component({
  selector: 'app-available-products',
  templateUrl: './available-products.component.html',
  styleUrls: ['./available-products.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AvailableProductsComponent implements OnInit, OnDestroy {
  @Input() countryId: number;
  @Input() selectedProducts: Product[];
  @Output() onSelectProduct = new EventEmitter<Product>();
  @Output() onUnSelectProduct = new EventEmitter<Product>();

  carouselItemList: { product: Product, checked: boolean }[];
  filteredProductsItems: { product: Product, checked: boolean }[];
  isLoading: boolean;
  notifications: Message[] = [];
  products: Product[];
  queryName = '';
  spinnerText: string;
  sub: Subscription;

  constructor(private arpOrderService: ArpOrderService, private wizardHelperService: WizardHelperService) {
  }

  ngOnInit() {
    // Timeout is needed for carousel takes full width of parent
    setTimeout(() => {
      this.getAvailableProducts();
      this.unCheckedToggleBtn();
    }, 50);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // forming product list for carousel component
  createCarouselItems(): void {
    this.carouselItemList = this.products.map(product => {
      return {product, checked: false};
    });
    this.selectedProducts.map(selectedProduct => {
      const selectedProductItem = this.carouselItemList.find(item => item.product.productId === selectedProduct.productId);
      if (selectedProductItem) {
        selectedProductItem.checked = true;
      }
    });
    this.filteredProductsItems = this.carouselItemList.slice();
  }

  // get product list for some country from service
  getAvailableProducts(): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    if (this.arpOrderService.availableProducts) {
      this.products = this.arpOrderService.availableProducts;
      this.isLoading = false;
      this.spinnerText = null;
      this.createCarouselItems();
    } else {
      this.arpOrderService.getAvailableProducts(this.countryId).subscribe(response => {
        this.products = response;
        this.isLoading = false;
        this.spinnerText = null;
        this.createCarouselItems();
      }, error => {
        this.isLoading = false;
        this.spinnerText = null;
        this.notifications.push({severity: 'error', summary: error.json().error_description});
      });
    }
  }

  // filter product list by product name
  searchProduct(): void {
    this.filteredProductsItems = this.carouselItemList.filter(item => {
      return item.product.productName.toLowerCase().indexOf(this.queryName.toLowerCase()) > -1 ||
        item.product.itemNumber.toLowerCase().indexOf(this.queryName.toLowerCase()) > -1 ||
        item.product.csvKeywords.toString().indexOf(this.queryName.toLowerCase()) > -1 ||
        item.product.price.toString().indexOf(this.queryName.toLowerCase()) > -1;
    });
  }

  // handle checked/unchecked product item and pass it to parent component
  handleChange(event, product: Product): void {
    if (event.checked) {
      // this.selectedProducts.push(product);
      this.onSelectProduct.emit(product);
    } else {
      // this.selectedProducts = this.selectedProducts.filter(item => item.productId !== product.productId);
      this.onUnSelectProduct.emit(product);
    }
  }

  // handle checked/unchecked product item in available product list
  unCheckedToggleBtn(): void {
    this.sub = this.wizardHelperService.removedProductSubject.subscribe((product: Product) => {
      const removedProduct = this.carouselItemList.find(item =>
        item.product.productId === product.productId
      );
      removedProduct.checked = false;
    });
  }
}
