import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {UserInfo} from '../../classes/user-info';

interface IPanelsCollapsed {
  affiliateBasicInfo: boolean;
  compensationInformation: boolean;
  contacts: boolean;
  mediaLibrary: boolean;
  messageCenter: boolean;
  printedMaterial: boolean;
  tutorials: boolean;
}

@Component({
  selector: 'app-affiliate-office',
  templateUrl: './affiliate-office.component.html',
  styleUrls: ['./affiliate-office.component.css']
})
export class AffiliateOfficeComponent implements OnInit {
  currentUser: UserInfo = new UserInfo;
  panelsCollapsed: IPanelsCollapsed = {
    affiliateBasicInfo: true,
    compensationInformation: true,
    contacts: true,
    mediaLibrary: true,
    messageCenter: false,
    printedMaterial: true,
    tutorials: true
  };

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.currentUser = this.userService.getCurrentUser();
  }
}
