import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Message} from 'primeng/primeng';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from '../../../services/google-analytics.service';
import {PasswordService} from '../../../services/password.service';
import {UserService} from '../../../services/user.service';
import {showError} from '../../../helpers/form-helper';


@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
  @Input() panelCollapsed: boolean;
  @Input() memberId: number;

  isLoading = false;
  changePasswordForm: FormGroup;
  notifications: Message[] = [];
  showError: Function = showError;
  submitted = false;
  spinnerText: string;

  constructor(
    private fb: FormBuilder,
    private gaService: GoogleAnalyticsService,
    private passwordService: PasswordService,
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  /**
   * Build model driven changePasswordForm form
   */
  buildForm(): void {
    this.changePasswordForm = this.fb.group(
      {
        'oldPassword': ['', Validators.required],
        'newPassword': ['', Validators.required],
        'repeatPassword': ['', Validators.required]
      }
    );
  }

  /**
   * Change current password
   */
  onSubmit(): void {
    this.submitted = true;
    if (
      this.changePasswordForm.valid &&
      this.changePasswordForm.dirty &&
      this.changePasswordForm.controls['newPassword'].value === this.changePasswordForm.controls['repeatPassword'].value
    ) {
      this.notifications = [];
      this.isLoading = true;
      this.spinnerText = 'Processing your request...';
      this.passwordService.changePassword(this.memberId, this.changePasswordForm.value).subscribe(response => {
        this.isLoading = false;
        this.spinnerText = null;
        this.notifications.push({severity: 'success', summary: 'Your Password was changed'});
        this.resetForm();
        this.gaService.sendGoogleAnalyticsEvent('changePassword');
      }, error => {
        this.isLoading = false;
        this.spinnerText = null;
        this.notifications.push({severity: 'error', summary: error.json().error_description});
      });
    }
  }

  onForgotPasswordClick(): void {
    this.gaService.sendGoogleAnalyticsEvent('forgotPassword');
    this.userService.logOut();
    this.router.navigate(['/forgot-password']);
  }

  /**
   * Password panel toggle handler
   * @param event - dom event object
   */
  onPanelToggle(event: { originalEvent: Event, collapsed: boolean }): void {
    if (!event.collapsed) {
      this.gaService.sendGoogleAnalyticsEvent('passwordOpen');
    }
  }

  // reset form
  resetForm(): void {
    this.submitted = false;
    this.changePasswordForm.reset();
  }

}
