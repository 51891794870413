import {Component, Input, OnInit} from '@angular/core';
import {AffiliateService} from '../../../services/affiliate.service';
import {Message} from 'primeng/primeng';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.css']
})
export class TutorialsComponent implements OnInit {
  @Input() panelCollapsed: boolean;
  isLoading: false;
  notifications: Message[] = [];
  tutorials: any[] = [];

  constructor(private affiliateService: AffiliateService) {
  }

  ngOnInit() {
    this.getTutorials();
  }

  // Get tutorials from API using service
  getTutorials() {
    this.affiliateService.getTutorials().subscribe(response => {
      this.tutorials = response;
    }, error => {
      this.notifications.push({severity: 'error', summary: 'Something went wrong with Tutorials.'});
    });
  }
}
