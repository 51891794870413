import {Component} from '@angular/core';
import {LocalStorage} from '../../helpers/local-storage.decorator';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @LocalStorage() user;
}
