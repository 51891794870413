import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Message, SelectItem} from 'primeng/primeng';
import {PaymentService} from '../../../../../services/payment.service';
import {Address, ArpOrder, Payment} from '../../../../../classes/arp-order';
import {Country} from '../../../../../classes/country';
import {WizardHelperService} from '../../../../../services/wizard-helper.service';

@Component({
  selector: 'app-select-payment-method-step',
  templateUrl: './select-payment-method-step.component.html',
  styleUrls: ['./select-payment-method-step.component.css']
})
export class SelectPaymentMethodStepComponent implements OnInit {

  @Input() arpOrder: ArpOrder;
  @Input() billingAddress: Address;
  @Input() country: Country;
  @Input() form: FormGroup;
  @Input() memberId: string;

  @Output() onDialogDecentered = new EventEmitter<null>();
  @Output() onClickNextBtn = new EventEmitter<string>();
  @Output() onChangeStepTitle = new EventEmitter<string>();

  creditCardsTypes: SelectItem [] = [];
  dialogContentMaxWidth: number = window.innerWidth - 300;
  isLoading = false;
  notifications: Message[] = [];
  paymentList: any = [];
  spinnerText: string;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(private paymentService: PaymentService, private wizardHelperService: WizardHelperService) {
  }

  ngOnInit() {
    if (!this.form.controls.cardId) {
      this.addControls();
    }
    this.getPaymentOptions();
    this.getCardList();
  }

  // add 'payment' field to general form
  addControls(): void {
    this.form.addControl('cardId', new FormControl('', Validators.required));
  }

  // close add credit card form
  addPayment(): void {
    delete this.form.controls.cardId;
    this.onClickNextBtn.emit('addEditPaymentMethod');
  }

  // move to previous wixard step
  backToPreviousStep(): void {
    this.onClickNextBtn.emit('selectShippingAddress');
  }

  // handler toggle btn event
  changeCheckedPayment(checked, payment) {
    delete this.form.controls.payment;
    if (checked) {
      this.form.controls.cardId.setValue(payment.cardId);
      this.paymentList.map(item => {
        item.checked = false;
        if (item.card.cardId === payment.cardId) {
          item.checked = true;
        }
      });
    } else {
      this.form.controls.cardId.setValue('');
      this.paymentList.map(item => {
        item.checked = false;
      });
    }
  }

  // get all cards for this memberId and set card list to object
  getCardList(): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.paymentService.getPaymentList(this.memberId).subscribe(response => {
      this.isLoading = false;
      if (!response.cards.length) {
        this.addPayment();
      } else {
        this.wizardHelperService.setCardList(response.cards);
        this.paymentList = response.cards;
        this.transformPaymentList(this.paymentList);
        this.wizardHelperService.setCardList(response.cards);

        setTimeout(() => this.onDialogDecentered.emit());
      }
    }, error => {
      this.isLoading = false;
      this.notifications.push({severity: 'error', summary: error.json().error_description});
    });
  }

  // get credit card options for add/edit card form
  getPaymentOptions(): void {
    this.paymentService.getCardTypes().subscribe(response => {
      const creditCardsTypes = response.countries[2].cardTypes;
      for (let i = 0; i < creditCardsTypes.length; i++) {
        this.creditCardsTypes.push({value: creditCardsTypes[i].value.toString(), label: creditCardsTypes[i].displayName});
      }
    }, error => {
      this.notifications.push({severity: 'error', summary: error.json().error_description});
    });
  }

  // remove payment from card list by id
  removePayment(payment): void {
    // this.isLoading = true;
    console.log('removed this payment');
  }

  /**
   * Updarte component addresses list and set shipping address id
   * @param addresses - addresses list
   */
  transformPaymentList(paymentList: Payment[]): void {
    const paymentItems = paymentList.map(card => {
      if (this.form.controls.cardId && card.cardId === this.form.controls.cardId.value) {
        return {card, checked: true};
      }
      return {card, checked: false};
    });
    this.paymentList = paymentItems.slice();
  }

  // move to next wizard step
  finishThisStep(): void {
    this.onClickNextBtn.emit('selectBillingAddress');
  }
}
