import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpService} from '../services/http.service';
import {LogOutService} from '../services/log-out.service';
import {UserInfo} from '../classes/user-info';
import {WellnessStoreState} from '../modules/wellness-store/store/wellness-store.reducer';
import {Store} from '@ngrx/store';
import {clearStore} from '../modules/wellness-store/store/wellness-store.actions';

@Injectable()
export class UserService {
  private user: UserInfo;
  private userSubject: BehaviorSubject<UserInfo> = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private httpService: HttpService,
    private logOutService: LogOutService,
    private store: Store<{wellnessStore: WellnessStoreState}>
  ) {
  }

  get userStream(): Observable<UserInfo> {
    return this.userSubject.asObservable();
  }

  /**
   * Get info about current user
   */
  getCurrentUser(): UserInfo {
    if (this.user) {
      return JSON.parse(JSON.stringify(this.user));
    }
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user'));
    }
    return null;
  }

  /**
   * Get current user by token
   */
  getCurrentUserByToken(token: string): Observable<UserInfo> {
    return this.httpService.get(`profile`, 'global', token);
  }

  /**
   * Log out and remove current user info
   */
  logOut(): void {
    this.setCurrentUser(null);
    localStorage.removeItem('user');
    this.logOutService.setLogOutSubject(false);
    this.router.navigate(['/login']);
    this.store.dispatch(clearStore());
  }

  /**
   * Set current user info to this service and localStorage
   */
  setCurrentUser(user: UserInfo): void {
    this.user = JSON.parse(JSON.stringify(user));
    this.userSubject.next(this.user);
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  /**
   * Gets from API vita points quantity from profile info
   */
  updateUserInfo(): Observable<any> {
    const userToken = this.getCurrentUser().token;
    const memberId = this.getCurrentUser().memberId;
    return this.httpService.get(`profile/${memberId}`, 'cart', userToken);
  }
}

