import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class LogOutService {
  private _logOutSubject: Subject<boolean> = new Subject();

  constructor() {
  }

  // change isLogOut value to removing token
  public setLogOutSubject(isLogOut): void {
    this._logOutSubject.next(isLogOut);
  }

  get logOutSubject(): Subject<boolean> {
    return this._logOutSubject;
  }
}
