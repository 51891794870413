import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AffiliateService} from './../../../services/affiliate.service';
import {IMediaLibraryVideo} from '../../../classes/media-library-video';
import {Message} from 'primeng/primeng';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.css']
})
export class MediaLibraryComponent implements OnInit {
  @Input() panelCollapsed: boolean;
  @Output() panelToggled = new EventEmitter(null);

  // TODO: add interface or class
  banners: any[] = [
    {
      src: '../assets/img/banner1.jpg',
      title: 'How to Use Resource Center'
    },
    {
      src: '../assets/img/banner2.jpg',
      title: 'Tips for Posting To Facebook'
    }
  ];
  memberId: string;
  dialogsVisibility: any = {
    emailDialogIsVisible: false,
    embedCodeDialogIsVisible: false,
    viewBannerDialogVisible: false,
  };
  emailDialogVideo: IMediaLibraryVideo;
  embedVideoCode: string;
  isLoading: false;
  notifications: Message[] = [];
  // TODO: add class/interface to viewBanner prop
  viewBanner: any;
  videos: IMediaLibraryVideo[] = [];

  constructor(
    private affiliateService: AffiliateService,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.memberId = this.userService.getCurrentUser().memberId;
    this.getVideos();
  }

  // Get videos from API using service
  getVideos() {
    this.affiliateService.getVideos(this.memberId).subscribe(videos => {
      this.videos = videos;
    }, error => {
      this.notifications.push({severity: 'error', summary: 'Something went wrong with Media Library Videos.'});
    });
  }

  /**
   * Sets dialogs visibility to false
   * @param dialogVisibilityProperty
   */
  onDialogHide(dialogVisibilityProperty: string) {
    this.dialogsVisibility[dialogVisibilityProperty] = false;

    // Reset dialogs properties
    this.emailDialogVideo = null;
    this.embedVideoCode = null;
  }

  /**
   * Open View order dialog
   * @param order
   */
  openViewCodeDialog(banner: any): void {
    this.viewBanner = banner;
    this.dialogsVisibility.viewBannerDialogVisible = true;
  }

  /**
   * Parse embed code string and open embed code dialog
   */
  openEmbedVideoCodeDialog(videoUrl: string): void {
    this.embedVideoCode = `<iframe src="${videoUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
    this.dialogsVisibility.embedCodeDialogIsVisible = true;
  }

  /**
   * Open email video message dialog
   */
  openEmailVideoMessageDialog(video: IMediaLibraryVideo): void {
    this.emailDialogVideo = JSON.parse(JSON.stringify(video));
    this.dialogsVisibility.emailDialogIsVisible = true;
  }
}
