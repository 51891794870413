import {Component, Input, OnInit} from '@angular/core';
import {AffiliateService} from '../../../services/affiliate.service';
import {Message} from 'primeng/primeng';
import {IHtmlTemplate} from '../../../classes/html-template';

@Component({
  selector: 'app-printed-material',
  templateUrl: './printed-material.component.html',
  styleUrls: ['./printed-material.component.css']
})
export class PrintedMaterialComponent implements OnInit {
  @Input() panelCollapsed: boolean;
  isLoading: false;
  materials: IHtmlTemplate[] = [];
  notifications: Message[] = [];

  constructor(private affiliateService: AffiliateService) {
  }

  ngOnInit() {
    this.getPrintedMaterials();
  }

  // Get printed materials from API using service
  getPrintedMaterials() {
    this.affiliateService.getPrintedMaterials().subscribe(response => {
      this.materials = response;
    }, error => {
      this.notifications.push({severity: 'error', summary: 'Something went wrong with Printed Materials.'});
    });
  }

}
