import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Patterns} from '../../../helpers/helper';
import {showError} from '../../../helpers/form-helper';
import {UserInfo} from '../../../classes/user-info';
import {AddressService} from '../../../services/address.service';
import {ContactInfoService} from '../../../services/contact-info.service';
import {GoogleAnalyticsService} from '../../../services/google-analytics.service';
import {UserService} from '../../../services/user.service';
import {WizardHelperService} from '../../../services/wizard-helper.service';
import {Address} from '../../../classes/arp-order';
import {Country} from '../../../classes/country';
import {Message} from 'primeng/primeng';
import {debounceTime, distinctUntilChanged, filter, flatMap, map} from 'rxjs/operators';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnInit, OnDestroy {
  @Input() country: Country;
  @Input() currentUser: UserInfo = new UserInfo;
  @Input() panelCollapsed: boolean;

  addresses: Address[];
  contactInformationEditForm: FormGroup;
  defaultBillingAddress: Address;
  defaultShippingAddress: Address;
  existingEmail: boolean;
  isEditing = false;
  phoneEditing = false;
  emailEditing = false;
  isLoading = false;
  notChangedBody: any;
  notifications: Message[] = [];
  phoneMask: (string | RegExp)[] = Patterns.phone.mask;
  saveButtonDisabled: boolean;
  showError: Function = showError;
  spinnerText: string;
  sub: Subscription;
  subControl: Subscription;
  submitted = false;
  isEditingBillingAddress = false;
  isEditingShippingAddress = false;

  constructor(
    private addressService: AddressService,
    private contactInfoService: ContactInfoService,
    private fb: FormBuilder,
    private gaService: GoogleAnalyticsService,
    private userService: UserService,
    private wizardHelperService: WizardHelperService
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.getAddresses();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // cancel editing some contact info
  cancelEditing(): void {
    this.subControl.unsubscribe();
    this.existingEmail = false;
    this.phoneEditing = false;
    this.emailEditing = false;
    this.contactInformationEditForm.controls.email.setValue(this.notChangedBody.email);
    this.contactInformationEditForm.controls.phone.setValue(this.notChangedBody.phone);
    this.contactInformationEditForm.markAsPristine();
    this.isEditing = false;
    this.notChangedBody = null;
  }

  // finished changing addresses
  closeEditingAddressDialog() {
    this.isEditingBillingAddress = false;
    this.isEditingShippingAddress = false;
  }


  // get all addresses from api and set them to subject
  getAddresses(): void {
    this.sub = this.wizardHelperService.addressListSubject.subscribe(addressList => {
      if (!addressList.length) {
        this.addressService.getAllAddresses(this.currentUser.memberId).subscribe(response => {
          this.wizardHelperService.setAddressList(response.addresses);
          this.addresses = response.addresses;
          this.defineDefaultAddresses();
          this.isLoading = false;
          this.spinnerText = null;
        }, error => {
          if (error.status === 401 || error.status === 403) {
            this.userService.logOut();
          } else {
            this.notifications.push({severity: 'error', summary: error.json().error_description});
          }
        });
      } else {
        this.addresses = addressList;
        this.defineDefaultAddresses();
        this.isLoading = false;
        this.spinnerText = null;
      }
    });
  }

  defineDefaultAddresses(): void {
    this.addresses.map(item => {
      if (item.isDefaultBillingAddress) {
        this.defaultBillingAddress = item;
        this.contactInformationEditForm.controls.billingAddressId.setValue(this.defaultBillingAddress.addressId);
      }
      if (item.isDefaultShippingAddress) {
        this.defaultShippingAddress = item;
        this.contactInformationEditForm.controls.shippingAddressId.setValue(this.defaultShippingAddress.addressId);
      }
    });
  }

  /**
   * Build model driven contactInformationEditForm form
   */
  buildForm(): void {
    this.contactInformationEditForm = this.fb.group(
      {
        'email': [this.currentUser.email, [Validators.required, Validators.pattern(Patterns.email)]],
        'phone': [this.currentUser.phone, [Validators.required]],
        'billingAddressId': ['', Validators.required],
        'shippingAddressId': ['', Validators.required]
      }
    );
  }


  /**
   * Define type of edit address
   */
  editAddress(typeOfEditAddress: string) {
    this.isEditingBillingAddress = typeOfEditAddress === 'isEditingBillingAddress';
    this.isEditingShippingAddress = typeOfEditAddress === 'isEditingShippingAddress';
  }

  /**
   * Contact Information panel toggle handler
   * @param event - dom event object
   */
  onPanelToggle(event: { originalEvent: Event, collapsed: boolean }): void {
    if (!event.collapsed) {
      this.gaService.sendGoogleAnalyticsEvent('contactInformationOpen');
    }
  }


  // send updated info to service
  onSubmit(): void {
    this.submitted = true;
    this.notifications = [];
    if (this.contactInformationEditForm.valid && this.contactInformationEditForm.dirty && !this.existingEmail) {
      this.isLoading = true;
      this.spinnerText = 'Processing your request...';
      this.contactInfoService.updateContactInfo(this.contactInformationEditForm.value).subscribe(response => {
        this.currentUser.email = response.email;
        this.currentUser.phone = response.phone;
        this.subControl.unsubscribe();
        this.contactInformationEditForm.controls.email.setValue(this.currentUser.email);
        this.contactInformationEditForm.controls.email.setValue(this.currentUser.email);
        this.userService.setCurrentUser(this.currentUser);
        this.isLoading = false;
        this.spinnerText = null;
        this.submitted = false;
        this.isEditing = false;
        this.phoneEditing = false;
        this.emailEditing = false;
        this.existingEmail = false;
        this.saveButtonDisabled = false;
        this.contactInformationEditForm.markAsPristine();
        this.notifications.push({severity: 'success', summary: 'Contact info was changed'});
        this.gaService.sendGoogleAnalyticsEvent('updatePhoneOrEmail');
      }, error => {
        this.notifications.push({severity: 'error', summary: error.json().error_description});
        this.isLoading = false;
        this.submitted = false;
        this.spinnerText = null;
      });
    }
  }

  // start editing some contact info
  startEditing(): void {
    this.notChangedBody = this.contactInformationEditForm.value;
    this.isEditing = true;
    // Validation to check if email already exists in database
    this.subControl = this.contactInformationEditForm.controls.email.valueChanges
      .pipe(
        map(value => {
          this.saveButtonDisabled = true;
          return value;
        }),
        debounceTime(500), // Pause for 500ms
        distinctUntilChanged(), // Only if the value has changed
        filter(value => this.contactInformationEditForm.controls.email.valid),
        flatMap(value => this.contactInfoService.checkExistingEmail(
          this.contactInformationEditForm.value.email
        ))
      )
      .subscribe(response => {
        this.existingEmail = response.existingCustomer;
        this.saveButtonDisabled = false;
      });
  }

  /**
   * Sets pattern validator for phone field when users types in this field
   * @param {string} controlName
   */
  setPhonePatternValidator(controlName: string): void {
    this.contactInformationEditForm.controls[controlName]
      .setValidators(Validators.pattern(Patterns.phone.pattern));
  }

  showNotification(event): void {
    this.notifications = [];
    this.notifications.push({severity: event.severity, summary: event.message});
  }

}
