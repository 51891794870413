import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {UserService} from './user.service';


@Injectable()
export class ContactInfoService {

  constructor(private httpService: HttpService, private userService: UserService) {
  }


  checkExistingEmail(email: string): Observable<ExistingEmailResponse> {
    return this.httpService.get(`profile?email=${email}`, 'cart');
  }

  // Send updated contact info to API
  updateContactInfo(contactInfo): Observable<any> {
    return this.httpService.post(
      `profile/${this.userService.getCurrentUser().memberId}`,
      'global',
      contactInfo,
      this.userService.getCurrentUser().token,
      'application/json'
    );
  }
}

interface ExistingEmailResponse {
  existingCustomer: boolean;
  orderHistoryAmount: number;
  cannotTakeAdvantage?: boolean;
}
