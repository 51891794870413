import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {filter, finalize, switchMap, tap} from 'rxjs/operators';
import {Message} from 'primeng/primeng';
import {Patterns} from './../../../helpers/helper';
import {ReferService} from './../../../services/refer.service';
import {UserInfo} from './../../../classes/user-info';
import {UserService} from '../../../services/user.service';
import {showError} from './../../../helpers/form-helper';
import {GoogleAnalyticsService} from '../../../services/google-analytics.service';

const GiftCardUrl = 'https://activate.trivita.com/card-activation';
const GiftCardPDFUrl = 'https://api.trivita.com/GiftCardPDF/WellnessGiftCardHandler.ashx';

export interface GiftCard {
  AccountNumber: string;
  SecurityID: string;
  CardType: string;
  CardTypeCode: string;
  Name: string;
}

enum GoogleAnalyticsEventTypes {
  shareAndEarnCopyLink = 'shareAndEarnCopyLink',
  shareAndEarnOpenSendEmail = 'shareAndEarnOpenSendEmail',
  shareAndEarnSentEmail = 'shareAndEarnSentEmail',
  shareAndEarnDownloadPDF = 'shareAndEarnDownloadPDF'
}

@Component({
  selector: 'app-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.css']
})
export class ReferAFriendComponent implements OnInit, OnDestroy {
  @Input() currentUser: UserInfo;
  @Input() panelCollapsed: boolean;

  errorMessage: string;
  form: FormGroup;
  giftCardPDFUrl: string = GiftCardPDFUrl;
  giftCardUrl: string = GiftCardUrl;
  isEmailFormContent: boolean;
  isLoading: boolean;
  notifications: Message[] = [];
  showError: Function = showError;
  spinnerText: 'Processing your request...';
  submitted: boolean;
  visible: boolean;
  sub: Subscription;
  googleAnalyticsEventTypes = GoogleAnalyticsEventTypes;

  constructor(
    private fb: FormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    private referService: ReferService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.getGiftCardInfo();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  openDialog(): void {
    this.visible = true;
    this.buildForm();
    this.isEmailFormContent = true;
    this.sendEventInfoToGA(this.googleAnalyticsEventTypes.shareAndEarnOpenSendEmail);
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    this.submitted = true;
    this.isLoading = true;

    this.sub = this.referService.checkExistingEmail(this.form.value.email).pipe(
      finalize(() => {
        this.submitted = false;
        this.isLoading = false;
      }),
      tap(response => this.errorMessage = response.existingCustomer ? 'Email address belongs to an existing customer' : null),
      filter(response => !response.existingCustomer),
      switchMap(response => this.referService.sendGiftCardOnEmail(
        encodeURIComponent(this.form.value.email),
        this.currentUser.memberId,
        this.currentUser.token
        ),
      ))
      .subscribe(response => {
          this.sendEventInfoToGA(this.googleAnalyticsEventTypes.shareAndEarnSentEmail);
          this.isEmailFormContent = false;
          this.form.controls.email.reset();
        }, error => {
          this.notifications.push({severity: 'error', summary: error.json().Message ? error.json().Message : 'Somthing went wrong...'});
        }
      );
  }

  sendEventInfoToGA(eventType: string) {
    this.googleAnalyticsService.sendGoogleAnalyticsEvent(eventType);
  }

  private buildForm(): void {
    this.form = this.fb.group(
      {email: ['', [Validators.required, Validators.pattern(Patterns.email)]]}
    );
  }

  private createLinks(memberId: string, countryId: number): void {
    this.giftCardUrl += `?cardNumber=${this.currentUser.giftCard.AccountNumber}&pinNumber=${this.currentUser.giftCard.SecurityID}`;
    this.giftCardPDFUrl += `?id=${memberId}&sId=${this.currentUser.giftCard.SecurityID}&cId=${countryId}`;
  }

  private getGiftCardInfo(): void {
    this.currentUser = this.userService.getCurrentUser();

    if (this.currentUser && this.currentUser.giftCard) {
      this.currentUser.giftCard = this.currentUser.giftCard;
      this.createLinks(this.currentUser.memberId, this.currentUser.shippingAddress.countryId);
    } else {
      this.isLoading = true;
      const token = this.currentUser.token;
      this.userService.getCurrentUserByToken(token).subscribe(
        res => {
          this.currentUser = res;
          this.currentUser.giftCard = this.currentUser.giftCard;
          this.createLinks(this.currentUser.memberId, this.currentUser.shippingAddress.countryId);
          this.isLoading = null;
        }, error => {
          this.isLoading = false;
          this.notifications.push({severity: 'error', summary: error.json().Message});
        });
    }
  }

}
