import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {UserInfo} from '../../classes/user-info';
import {Country} from '../../classes/country';
import {CountryService} from '../../services/country.service';

interface IPanelsCollapsed {
  contactInformation: boolean;
  paymentMethod: boolean;
  orderHistory: boolean;
  autoReplenishmentProgram: boolean;
  referAFriend: boolean;
  password: boolean;
}

@Component({
  selector: 'app-account-managment',
  templateUrl: './account-managment.component.html',
  styleUrls: ['./account-managment.component.css']
})

export class AccountManagmentComponent implements OnInit, OnDestroy {
  country: Country;
  getCountriesWithStatesSubscription: Subscription;
  currentUser: UserInfo = new UserInfo;
  panelsCollapsed: IPanelsCollapsed = {
    contactInformation: true,
    paymentMethod: true,
    orderHistory: true,
    autoReplenishmentProgram: true,
    referAFriend: true,
    password: true
  };
  referAFriendIsVisible: boolean;

  constructor(private countryService: CountryService, private router: Router, private userService: UserService) {
  }

  ngOnInit() {
    this.checkUserIsLogged();
  }

  ngOnDestroy() {
    if (this.getCountriesWithStatesSubscription) {
      this.getCountriesWithStatesSubscription.unsubscribe();
    }
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  checkUserIsLogged(): void {
    this.currentUser = this.userService.getCurrentUser();

    this.referAFriendIsVisible = this.currentUser
      && (
        this.currentUser.isPremierMember === true || this.currentUser.isPremierMember === 'true'
        || this.currentUser.isItbo === true || this.currentUser.isItbo === 'true'
        || this.currentUser.isAffiliate === true || this.currentUser.isAffiliate === 'true'
      );

    if (!this.currentUser && location.pathname !== '/forgot-password') {
      this.router.navigate(['/login']);
    } else {
      this.defineCurrentCountry();
    }
  }

  /**
   * Get country for a country drop down along with their associated states
   */
  defineCurrentCountry() {
    const countryId = this.currentUser.billingAddress.countryId;
    this.getCountriesWithStatesSubscription = this.countryService.getCountriesWithStates().subscribe(response => {
      this.country = response.find(item => item.value === countryId);
    });
  }

  /**
   * Expands one of panel which exists in child components
   * @param panelName - name of property of this.panelsCollapsed object
   */
  expandPanel(panelName: string): void {
    this.panelsCollapsed[panelName] = false;
  }

  /**
   *
   * @param event - primeng generated event
   * @param panelName - name of property of this.panelsCollapsed object
   */
  updatePanelCollapsed(event: { originalEvent: Event, collapsed: boolean }, panelName: string): void {
    this.panelsCollapsed[panelName] = event.collapsed;
  }
}
