import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ArpOrder} from '../../../../../classes/arp-order';
import {Country} from '../../../../../classes/country';
import {UserInfo} from '../../../../../classes/user-info';

@Component({
  selector: 'app-arp-order-personal-info',
  templateUrl: './arp-order-personal-info.component.html',
  styleUrls: ['./arp-order-personal-info.component.css']
})
export class ArpOrderPersonalInfoComponent {
  @Input() country: Country;
  @Input() order: ArpOrder;
  @Input() user: UserInfo;

  @Output() onOpenManagementAddress: EventEmitter<{ order: ArpOrder, typeOfAddress: string }> = new EventEmitter(null);
  @Output() onOpenManagementPayment: EventEmitter<ArpOrder> = new EventEmitter(null);

  openAddressManagement(typeOfAddress: string): void {
    const editingOrder = {order: this.order, typeOfAddress: typeOfAddress};
    this.onOpenManagementAddress.emit(editingOrder);
  }

  openPaymentManagement(): void {
    const editingOrder = this.order;
    this.onOpenManagementPayment.emit(editingOrder);
  }
}
