import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../services/user.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {getHeaderConfiguration, HeaderConfiguration} from './header-configuration';
import {fetchSiteSettings, setCurrentStoreID, showSpinner} from '../../modules/wellness-store/store/wellness-store.actions';
import {WellnessStoreState} from '../../modules/wellness-store/store/wellness-store.reducer';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs/Rx';
import {LocalStorage} from '../../helpers/local-storage.decorator';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  @LocalStorage() user;
  options$: Observable<any[]>;
  currentStoreID$: Observable<any>;
  show = false;
  configuration: HeaderConfiguration[];
  activeSegment: HeaderConfiguration;
  activeSecondary = false;
  closed = true;
  headerShown = true;

  constructor
  (
    public userService: UserService,
    private router: Router,
    private store: Store<{wellnessStore: WellnessStoreState}>
  ) {}

  ngOnInit(): void {
    this.configuration = getHeaderConfiguration([
      this.user.isAffiliate === 'true' ? null : '/main/affiliate-office',
      this.user.accountFlags.includes(9995) ? null : '/super-admin'
    ]);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects)
    ).subscribe((path: string) => {
      this.headerShown = !path.includes('login');

      this.configuration.forEach((primary: HeaderConfiguration) => {
        if (path.includes(primary.value)) {
          primary.active = true;
          this.activeSegment = primary;
        } else {
          primary.active = false;
        }

        if (primary.secondary) {
          primary.secondary.forEach((secondary: HeaderConfiguration) => {
            secondary.active = path.includes(secondary.value);
          });

          this.activeSecondary = !!primary.secondary.find(secondary => secondary.active);
        }
      });
    });

    this.options$ = this.store.select(store => store.wellnessStore)
      .pipe(
        map(({customerStores}) => customerStores)
      );

    this.currentStoreID$ = this.store.select(store => store.wellnessStore)
      .pipe(
        map((wellnessStore) => wellnessStore.currentStoreID),
        filter(Boolean)
      );
  }

  changeModule(path, mobile?: boolean): void {
    this.router.navigateByUrl(path);

    if (mobile) {
      setTimeout(() => this.closed = true, 1000);
    }
  }

  changeSubModule(path): void {
    this.router.navigateByUrl(this.activeSegment.value + path);
  }

  changeModuleAndSubmodule(segment, path): void {
    this.activeSegment = segment;
    this.router.navigateByUrl(this.activeSegment.value + path);

    setTimeout(() => this.closed = true, 1000);
  }

  selectStore({value: currentStoreID}): void {
    this.store.dispatch(showSpinner());
    this.store.dispatch(setCurrentStoreID({currentStoreID}));
    this.store.dispatch(fetchSiteSettings());
  }

  switchMobileMenu(): void {
    this.closed = !this.closed;

    if (this.closed) {
      document.getElementById('chat-widget-container').style.display = 'block';
    } else {
      document.getElementById('chat-widget-container').style.display = 'none';
    }
  }
}
