import {createAction, props} from '@ngrx/store';

export const fetchCustomerInfo = createAction(
  '[Wellness Store] fetchCustomerInfo',
   props<{ user?: any }>()
);
export const fetchCategories = createAction(
  '[Wellness Store] fetchCategories'
);
export const fetchProducts = createAction(
  '[Wellness Store] fetchProducts',
  props<{ categories: any }>());
export const fetchSiteSettings = createAction(
  '[Wellness Store] fetchSiteSettings'
);
export const selectProducts = createAction(
  '[Wellness Store] selectProducts',
  props<any>());
export const saveProducts = createAction(
  '[Wellness Store] saveProducts',
  props<{ products: any }>());
export const saveSiteSettings = createAction(
  '[Wellness Store] saveSiteSettings',
  props<{ settings: any[] }>()
);
export const saveCustomerInfo = createAction(
  '[Wellness Store] saveCustomerInfo',
  props<{ customer: any }>()
);
export const saveStoreCategories = createAction(
  '[Wellness Store] saveStoreCategories',
  props<{ categories: any }>()
);
export const addProduct = createAction(
  '[Wellness Store] addProduct',
  props<{ product: any }>()
);
export const removeProduct = createAction(
  '[Wellness Store] removeProduct',
  props<{ product: any }>()
);
export const updateSettings = createAction(
  '[Wellness Store] updateSettings',
  props<{ newSettings: any }>()
);
export const submitSiteSettings = createAction(
  '[Wellness Store] submitSiteSettings'
);
export const fetchArticleTypes = createAction(
  '[Wellness Store] fetchArticleTypes'
);
export const fetchMediaTypes = createAction(
  '[Wellness Store] fetchMediaTypes'
);
export const saveArticleTypes = createAction(
  '[Wellness Store] saveArticleTypes',
  props<{ articleTypes: any }>()
);
export const saveMediaTypes = createAction(
  '[Wellness Store] saveMediaTypes',
  props<{ mediaTypes: any }>()
);
export const submitProducts = createAction(
  '[Wellness Store] submitProducts'
);
export const submitSuccess = createAction(
  '[Wellness Store] submitSuccess'
);
export const showSpinner = createAction(
  '[Wellness Store] showSpinner'
);
export const hideSpinner = createAction(
  '[Wellness Store] hideSpinner'
);
export const fetchSocialTypes = createAction(
  '[Wellness Store] fetchSocialTypes'
);
export const saveSocialTypes = createAction(
  '[Wellness Store] saveSocialTypes',
  props<{ socialTypes: any }>()
);
export const publishPost = createAction(
  '[Wellness Store] publishPost',
  props<{ [key: string]: any }>()
);
export const fetchFeeds = createAction(
  '[Wellness Store] fetchFeeds',
  props<{ page?: number }>()
);
export const saveFeeds = createAction(
  '[Wellness Store] saveFeeds',
  props<{ feeds }>()
);
export const deleteFeed = createAction(
  '[Wellness Store] deleteFeed',
  props<{ post: any }>()
);
export const deleteBlog = createAction(
  '[Wellness Store] deleteBlog',
  props<{ post: any }>()
);
export const updateFeed = createAction(
  '[Wellness Store] updateFeed',
  props<{ post: any }>()
);
export const updateBlog = createAction(
  '[Wellness Store] updateBlog',
  props<{ post: any }>()
);
export const deleteImages = createAction(
  '[Wellness Store] deleteImages',
  props<{ images: any[]}>()
);
export const deleteSiteImage = createAction(
  '[Wellness Store] deleteSiteImage',
  props<{ images: any[]}>()
);
export const fetchTrivitaBlog = createAction(
  '[Wellness Store] fetchTrivitaBlog',
  props<{ page?: number }>()
);
export const saveTrivitaBlog = createAction(
  '[Wellness Store] saveTrivitaBlog',
  props<{ trivitaBlogs: any[] }>()
);
export const fetchOwnBlog = createAction(
  '[Wellness Store] fetchOwnBlog',
  props<{ page?: number }>()
);
export const saveOwnBlog = createAction(
  '[Wellness Store] saveOwnBlog',
  props<{ ownBlogs: any[] }>()
);
export const publishBlogPost = createAction(
  '[Wellness Store] publishBlogPost',
  props<{ blog: any }>()
);
export const AddTrivitaBlog = createAction(
  '[Wellness Store] AddTrivitaBlog',
  props<{ blog: any }>()
);
export const RemoveTrivitaBlog = createAction(
  '[Wellness Store] RemoveTrivitaBlog',
  props<{ blog: any }>()
);
export const clearTrivitaBlog = createAction(
  '[Wellness Store] clearTrivitaBlog'
);
export const sumbitTrivitaBlog = createAction(
  '[Wellness Store] sumbitTrivitaBlog'
);
export const fetchSelectedProducts = createAction(
  '[Wellness Store] fetchSelectedProducts'
);
export const saveSelectedProducts = createAction(
  '[Wellness Store] saveSelectedProducts',
  props<{ products: any[] }>()
);
export const setCustomerStores = createAction(
  '[Wellness Store] setCustomerStores',
  props<{ options: any[] }>()
);
export const setCurrentStoreID = createAction(
  '[Wellness Store] setCurrentStoreID',
  props<{ currentStoreID: number }>()
);
export const loadTrivitaSelectedBlogs = createAction(
  '[Wellness Store] loadTrivitaSelectedBlogs'
);
export const saveTrivitaSelectedBlogs = createAction(
  '[Wellness Store] saveTrivitaSelectedBlogs',
  props<{ selectedTrivitaBlogs: any[] }>()
);
export const clearCache = createAction(
  '[Wellness Store] clearCache',
  props<{ clearType: string }>()
);
export const fetchUsers = createAction(
  '[Wellness Store] fetchUsers'
);
export const saveUsers = createAction(
  '[Wellness Store] saveUsers',
  props<{ users: any[] }>()
);
export const clearStore = createAction(
  '[Wellness Store] clearStore'
);
export const changeUser = createAction(
  '[Wellness Store] changeUser',
  props<{ user: any[] }>()
);
export const saveUser = createAction(
  '[Wellness Store] saveUser',
  props<{ customer: any }>()
);
