import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {AffiliateService} from '../../../services/affiliate.service';
import {UserService} from '../../../services/user.service';
import {UserInfo} from '../../../classes/user-info';
import {Customer} from '../../../classes/customer';
import {Message} from 'primeng/primeng';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactsComponent implements OnInit {
  @Input() currentUser: UserInfo = new UserInfo;
  @Input() panelCollapsed: boolean;
  customers: Customer[];
  customersDatatable = {
    pageIndex: 0,
    pageSize: 25,
    sortColumn: null,
    sortOrder: null,
    totalCustomers: null
  };
  filteredCustomers: Customer[];
  isLoading = false;
  notifications: Message[] = [];
  prospectsIsVisible = false;
  rangeDates;
  searchQuery: string;
  selectedCustomers;

  constructor(
    private affiliateService: AffiliateService,
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.getContacts();
  }

  // Get contacts from service
  getContacts(): void {
    const memberId = this.currentUser.memberId;
    this.affiliateService.getContacts(
      memberId,
      this.customersDatatable.pageIndex,
      this.customersDatatable.pageSize,
      this.customersDatatable.sortColumn,
      this.customersDatatable.sortOrder)
      .subscribe((contacts: any) => {
        this.customersDatatable.totalCustomers = contacts.totalCustomers;
        this.customers = contacts.customers;
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        if (error.status === 401 || error.status === 403) {
          this.userService.logOut();
        } else {
          this.notifications.push({severity: 'error', summary: 'Something went wrong with Contacts (Customers)'});
        }
      });
  }

  /**
   * Filter array by matches search query in field of array objects
   * @param array
   * @param fieldName
   * @param searchQuery
   */
  filter(array: any[], fieldName: string, searchQuery: string): any[] {
    return array.filter(i =>
      i[fieldName].toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  }

  /**
   * Invoked on paginator events and retreive new list of contacts
   * @param event - event from Primeng paginator
   */
  paginateCustomersDatatable(event: { page: number, rows: number }): void {
    this.customersDatatable.pageIndex = event.page;
    this.customersDatatable.pageSize = event.rows;
    this.isLoading = true;
    this.getContacts();
  }

  /**
   * Invoked on sorting events and rewrite list of contacts
   * @param event - event from Primeng sorting
   */
  sortingCustomersDatatable(event) {
    if (
      this.customersDatatable.sortColumn !== event.field ||
      this.customersDatatable.sortOrder !== (event.order === 1 ? '' : 'desc')
    ) {
      this.customersDatatable.sortColumn = event.field;
      this.customersDatatable.sortOrder = event.order === 1 ? '' : 'desc';
      this.isLoading = true;
      this.getContacts();
    }
  }
}
