import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ArpOrder} from '../../../../../classes/arp-order';
import {FormGroup} from '@angular/forms';
import {SelectItem} from 'primeng/primeng';
import {ArpOrderService, IntervalOptions} from '../../../../../services/arp-order.service';

@Component({
  selector: 'app-arp-order-option',
  templateUrl: './arp-order-option.component.html',
  styleUrls: ['./arp-order-option.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArpOrderOptionComponent implements OnInit {
  @Input() order: ArpOrder; // order object
  @Input() form: FormGroup; // edit order form

  @Output() onEditOrderClick: EventEmitter<ArpOrder> = new EventEmitter(null);

  intervalOptions: IntervalOptions;
  dayOfMonthOptions: SelectItem[] = [];
  pointsOptions = {true: 'Yes', false: 'No'};
  frequencyOptions: SelectItem[] = [
    {value: 1, label: 'Monthly'},
    {value: 2, label: 'Every Two Months'},
    {value: 3, label: 'Every Three Months'}
  ];
  usingPointsOptions: SelectItem[] = [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'}
  ];

  constructor(private arpOrderService: ArpOrderService) {
  }

  ngOnInit() {
    this.getConfigurationOptions();
    this.createDayOfMonthOptions();
  }

  createDayOfMonthOptions() {
    for (let i = 1; i < 29; i++) {
      this.dayOfMonthOptions.push({value: i, label: i.toString()});
    }
  }

  // get arp configuration options from service
  getConfigurationOptions(): void {
    if (this.arpOrderService.arpOrderOptions) {
      this.intervalOptions = this.arpOrderService.arpOrderOptions;
    } else {
      this.arpOrderService.setConfigurationOptions().subscribe(response => {
        this.intervalOptions = response;
      });
    }
  }

}
