import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from 'primeng';
import {WizardHelperService} from '../../../../../../services/wizard-helper.service';
import {Payment} from '../../../../../../classes/arp-order';
import {first} from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-arp-order-card-view',
  templateUrl: './arp-order-card-view.component.html',
  styleUrls: ['./arp-order-card-view.component.css']
})
export class ArpOrderCardViewComponent implements OnInit {
  @Input() payment: Payment;

  @Output() onOpenPaymentManagement: EventEmitter<null> = new EventEmitter(null);


  isLoading: boolean;
  notifications: Message[] = [];

  constructor(private wizardHelperService: WizardHelperService) {
  }

  ngOnInit() {
    if (!this.payment.cardNumber) {
      this.wizardHelperService.cardListSubject
        .pipe(
          first()
        ).subscribe(cards => {
        this.payment = cards.find(item => item.cardId === this.payment);
      });
    }
  }

}
