import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfo} from '../../../classes/user-info';
import {DashboardInfo} from '../../../classes/dashboard-info';
import {AffiliateService} from '../../../services/affiliate.service';
import {UserService} from './../../../services/user.service';
import {Message} from 'primeng/primeng';

@Component({
  selector: 'app-affiliate-basic-info',
  templateUrl: './affiliate-basic-info.component.html',
  styleUrls: ['./affiliate-basic-info.component.css']
})
export class AffiliateBasicInfoComponent implements OnInit {
  @Input() currentUser: UserInfo = new UserInfo;
  dashboardInfo: DashboardInfo;
  isLoading = false;
  notifications: Message[] = [];

  constructor(private affiliateService: AffiliateService, private router: Router, private userService: UserService) {
  }

  ngOnInit() {
    this.getDashboardInfo();
  }

  // Get dashboard information from service
  getDashboardInfo(): void {
    const memberId = this.currentUser.memberId;
    this.isLoading = true;
    this.affiliateService.getDashboardInfo(memberId).subscribe((dashboardInfo: DashboardInfo) => {
      this.dashboardInfo = dashboardInfo;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      if (error.status === 401 || error.status === 403) {
        this.userService.logOut();
      } else {
        this.notifications.push({severity: 'error', summary: 'Something went wrong with Dasboard Information.'});
      }
    });
  }

}
