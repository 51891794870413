import {APP_INITIALIZER, InjectionToken, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {environment} from '../environments/environment';
import {appRoutes} from './app.routes';
import {MainModule} from './modules/main/main.module';
import {MomentModule} from 'angular2-moment';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {NgxMaskModule} from 'ngx-mask';
import {SharedModule} from './modules/shared/shared.module';
import {AppComponent} from './app.component';
import {CreatePasswordFormComponent} from './components/forgot-password/create-password-form/create-password-form.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {LoginComponent} from './components/login/login.component';
import {LoginFormComponent} from './components/login/login-form/login-form.component';
import {ResetPasswordFormComponent} from './components/forgot-password/reset-password-form/reset-password-form.component';
import {AddressService} from './services/address.service';
import {AffiliateService} from './services/affiliate.service';
import {ArpOrderService} from './services/arp-order.service';
import {CountryService} from './services/country.service';
import {ConfigService} from './services/config.service';
import {ContactInfoService} from './services/contact-info.service';
import {GoogleAnalyticsService} from './services/google-analytics.service';
import {HttpService} from './services/http.service';
import {IdleService} from './services/idle.service';
import {LoginService} from './services/login.service';
import {LogOutService} from './services/log-out.service';
import {OrderHistoryService} from './services/order-history.service';
import {PasswordService} from './services/password.service';
import {PaymentService} from './services/payment.service';
import {ProfileService} from './services/profile.service';
import {StoreParamsService} from './services/store-params.service';
import {UserService} from './services/user.service';
import {WizardHelperService} from './services/wizard-helper.service';
import {ReferService} from './services/refer.service';
import {HeaderComponent} from './components/header/header.component';
import {HeaderDropdownComponent} from './components/header/header-dropdown/header-dropdown.component';
import {FooterComponent} from './components/footer/footer.component';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {BaseUrlInterceptorFactory} from './interceptors/base-url.interceptor';
import {TokenInterceptorFactory} from './interceptors/token.interceptor';
import {EffectsModule} from '@ngrx/effects';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';

export const ENV = new InjectionToken('environment');

@NgModule({
  declarations: [
    AppComponent,
    CreatePasswordFormComponent,
    ForgotPasswordComponent,
    LoginComponent,
    LoginFormComponent,
    ResetPasswordFormComponent,
    HeaderComponent,
    HeaderDropdownComponent,
    FooterComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    MainModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    RouterModule.forRoot(appRoutes),
    NgxMaskModule.forRoot(),
    SharedModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({}),
    ToastModule
  ],
  providers: [
    AddressService,
    AffiliateService,
    ArpOrderService,
    ConfigService,
    ContactInfoService,
    CountryService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService],
      multi: true
    },
    GoogleAnalyticsService,
    HttpService,
    IdleService,
    LoginService,
    LogOutService,
    OrderHistoryService,
    PaymentService,
    PasswordService,
    ProfileService,
    ReferService,
    StoreParamsService,
    UserService,
    WizardHelperService,
    MessageService,
    {provide: ENV, useValue: environment},
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: TokenInterceptorFactory,
      deps: [Router, ConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: BaseUrlInterceptorFactory,
      deps: [ConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}

export function ConfigLoader(configService: ConfigService) {
  // this factory need to return a function (that return a promise)
  return () => configService.load(environment.configFile);
}
