import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Message} from 'primeng/primeng';
import {AddressService} from './../../../services/address.service';
import {ArpOrderService, IntervalOptions} from '../../../services/arp-order.service';
import {GoogleAnalyticsService} from '../../../services/google-analytics.service';
import {WizardHelperService} from './../../../services/wizard-helper.service';
import {UserService} from './../../../services/user.service';
import {ArpOrder} from '../../../classes/arp-order';
import {Country} from '../../../classes/country';
import {UserInfo} from '../../../classes/user-info';

@Component({
  selector: 'app-auto-replenishment-program',
  templateUrl: './auto-replenishment-program.component.html',
  styleUrls: ['./auto-replenishment-program.component.css']
})
export class AutoReplenishmentProgramComponent implements OnInit, OnDestroy {
  @Input() country: Country;
  @Input() panelCollapsed: boolean;
  @Input() user: UserInfo;

  arpConfiguration: IntervalOptions;
  isLoading: boolean;
  notifications: Message[] = [];
  orderList: ArpOrder[];
  wizardDialogIsOpen: boolean;
  spinnerText: string;
  sub: Subscription;

  constructor(
    private addressService: AddressService,
    private arpOrderService: ArpOrderService,
    private gaService: GoogleAnalyticsService,
    private userService: UserService,
    private wizardHelperService: WizardHelperService
  ) {
  }

  ngOnInit() {
    this.getConfigurationOptions();
    this.getArpOrders();
    this.getAddresses();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // get order list for current user
  getArpOrders() {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.arpOrderService.getArpOrders(this.user.memberId).subscribe(response => {
      this.orderList = response.arpOrders;
      this.wizardHelperService.setOrderList(response.arpOrders);
      this.sub = this.wizardHelperService.orderListSubject.subscribe(orders => {
        this.orderList = orders;
      });
      this.isLoading = false;
      this.spinnerText = null;
    }, error => {
      this.isLoading = false;
      this.spinnerText = null;
      if (error.status === 401 || error.status === 403) {
        this.userService.logOut();
      } else {
        this.notifications.push({severity: 'error', summary: 'Something went wrong with Auto Replenishment Program.'});
      }
    });
  }

  // get all addresses from api and set them to subject
  getAddresses(): void {
    this.addressService.getAllAddresses(this.user.memberId).subscribe(response => {
      this.wizardHelperService.setAddressList(response.addresses);
    }, error => {
      if (error.status === 401 || error.status === 403) {
        this.userService.logOut();
      } else {
        this.notifications.push({severity: 'error', summary: error.json().error_description});
      }
    });
  }

  // get arp configuration options from service
  getConfigurationOptions(): void {
    if (this.arpOrderService.arpOrderOptions) {
      this.arpConfiguration = this.arpOrderService.arpOrderOptions;
    } else {
      this.arpOrderService.setConfigurationOptions().subscribe(response => {
        this.arpConfiguration = response;
      });
    }
  }

  // update order list
  onAddOrderHandler(orders: ArpOrder[]): void {
    if (orders === null) {
      return;
    } else {
      this.orderList = orders;
    }
  }

  /**
   * Arp order panel toggle handler
   * @param event - dom event object
   */
  onPanelToggle(event: { originalEvent: Event, collapsed: boolean }): void {
    if (!event.collapsed) {
      this.gaService.sendGoogleAnalyticsEvent('arpOpen');
    }
  }
}
