import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GoogleAnalyticsService} from './google-analytics.service';
import {HttpService} from './http.service';
import {UserService} from './user.service';
import {ArpOrder, Product} from '../classes/arp-order';
import {map} from 'rxjs/operators';

@Injectable()
export class ArpOrderService {
  private _availableProducts: Product[];
  private _arpOrderOptions: IntervalOptions;


  constructor(
    private gaService: GoogleAnalyticsService,
    private httpService: HttpService,
    private userService: UserService
  ) {
  }

  get userToken(): string {
    return this.userService.getCurrentUser().token;
  }

  // add or edit arp order
  addArpOrder(memberId, countryId, arpOrder: ArpOrder, previewOnly: boolean): Observable<any> {
    arpOrder.memberId = +memberId;
    arpOrder.orderId = 0;
    arpOrder.countryId = countryId;
    arpOrder.previewOnly = previewOnly;
    return this.httpService.post(`arp/${memberId}`, 'account', arpOrder, this.userToken);
  }

  // for cancelling an ARP order
  cancelArpOrder(memberId, orderId): Observable<any> {
    return this.httpService
      .put(`arp/${memberId}/?orderId=${orderId}`, 'account', null, this.userToken)
      .pipe(
        map(response => {
          this.gaService.sendGoogleAnalyticsEvent('cancelArpOrder');
          return response;
        })
      );
  }

  // edit arp order
  editArpOrder(arpOrder: ArpOrder): Observable<any> {
    return this.httpService
      .post(`arp/${arpOrder.memberId}`, 'account', arpOrder, this.userToken);
  }

  // get arp orders
  getArpOrders(memberId): Observable<{ arpOrders: ArpOrder[] }> {
    return this.httpService.get(`arp/${memberId}`, 'account', this.userToken);
  }

  // get all available product from api
  getAvailableProducts(countryId): Observable<Product[]> {
    return this.httpService.get(`products/?countryId=${countryId}`, 'account')
      .pipe(
        map((products): Product[] => {
          products.forEach(productItem => {
            productItem.cartImage = productItem.cartImage.replace('-sm.', '-lrg.');
          });
          this.setAvailableProducts(products);
          return products;
        })
      );
  }

  // get all available product from variable
  get availableProducts(): Product[] {
    return this._availableProducts;
  }

  // get all available product from variable
  setAvailableProducts(products: Product[]): void {
    this._availableProducts = products;
  }

  // TODO: use this function in app instead hardcode
  // get options to defining frequency order
  setConfigurationOptions(): Observable<IntervalOptions> {
    return this.httpService.get(`arp/`, 'account')
      .pipe(
        map((options): IntervalOptions => {
          this._arpOrderOptions = options;
          return options;
        })
      );
  }

  // set arp order options
  get arpOrderOptions(): IntervalOptions {
    return this._arpOrderOptions;
  }

  // for skipping an entire order
  skipArpOrder(memberId, orderId): Observable<any> {
    return this.httpService
      .put(`arp/${memberId}/skiparp/${orderId}`, 'account', null, this.userToken)
      .pipe(
        map(response => {
          this.gaService.sendGoogleAnalyticsEvent('skipArpOrder');
          return response;
        })
      );
  }

  // for skipping an item in an order
  skipItemInArpOrder(memberId, orderId, productId): Observable<any> {
    return this.httpService
      .put(`arp/${memberId}/skiparp/${orderId}/product/${productId}`, 'account', null, this.userToken)
      .pipe(
        map(response => {
          this.gaService.sendGoogleAnalyticsEvent('skipArpProduct');
          return response;
        })
      );
  }
}

export interface IntervalOptions {
  1: string;
  2: string;
  3: string;
}
