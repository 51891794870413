import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {Message} from 'primeng/primeng';
import {showError} from '../../../helpers/form-helper';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['../forgot-password.component.css']
})
export class ResetPasswordFormComponent {
  @Input() nextStepName: string;
  @Output() onResetPasswordRequest = new EventEmitter<string>();

  isLoading = false;
  notifications: Message[] = [];
  resetPasswordForm: FormGroup;
  submitted = false;
  showError: Function = showError;

  constructor(private fb: FormBuilder, private loginService: LoginService) {
    this.buildForm();
  }

  buildForm(): void {
    // TODO: add getEnteredEMail from logic service as done in Homedoc
    // TODO: move pattern to some file as was done in homedoc
    // get entered email from login-form
    const email = this.loginService.getEnteredEmail();
    this.resetPasswordForm = this.fb.group(
      {
        'email': [email, [
          Validators.required
        ]]
      }
    );
  }

  goToNextStep(): void {
    this.onResetPasswordRequest.emit(this.nextStepName);
  }

  onSubmit(): void {
    this.submitted = true;
    const redirectUrl = encodeURIComponent(location.href);
    if (this.resetPasswordForm.valid && this.resetPasswordForm.dirty) {
      this.isLoading = true;
      this.loginService.sendResetPasswordRequest(this.resetPasswordForm.controls['email'].value, redirectUrl)
        .subscribe(res => {
          this.isLoading = false;
          this.goToNextStep();
        }, error => {
          this.isLoading = false;
          this.notifications.push({severity: 'error', summary: 'This email is not registered! Try again, please.'});
        });
    }
  }
}
