import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {GoogleAnalyticsService} from '../../../services/google-analytics.service';
import {OrderHistoryService} from '../../../services/order-history.service';
import {OrderHistoryItem} from '../../../classes/order-history-item';
import {Country} from '../../../classes/country';
import {Product} from '../../../classes/arp-order';
import {Message} from 'primeng/primeng';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderHistoryComponent implements OnInit {
  @Input() country: Country;
  @Input() memberId: number;
  @Input() panelCollapsed: boolean;
  @Output() panelToggled = new EventEmitter(null);

  calendarInputYearRange: string;
  paginationDetails = {
    pageIndex: 0,
    pageSize: 25,
    totalOrders: null
  };
  orderItems: OrderHistoryItem[];
  filteredOrders: OrderHistoryItem[] = [];
  visible = false;
  paymentsDatesRange: Date[];
  dialogTitle: string;
  dialogContentMaxHeight: number = window.innerHeight - 300;
  isLoading: boolean;
  notifications: Message[] = [];
  spinnerText: string;
  subtotal = 0;
  // TODO: add class/interface to viewOrder prop
  viewOrder: any;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.dialogContentMaxHeight = event.target.innerHeight - 300;
  }

  constructor(private gaService: GoogleAnalyticsService, private orderHistoryService: OrderHistoryService) {
    this.calendarInputYearRange = `${(new Date).getFullYear() - 20}:${(new Date).getFullYear()}`;
    this.setPaymentsDatesRange();
  }

  ngOnInit() {
    this.getOrderHistory();
  }

  closeDialog() {
    this.visible = false;
    this.viewOrder = null;
  }

  countSubtotal(products: Product[]): void {
    this.subtotal = 0;

    products.forEach(product => {
      const totalPrice = product.quantity * product.price;
      this.subtotal += totalPrice;
    });
  }

  /**
   * Filter array by date range
   * @param searchQuery
   */
  filter(datesRange: Date[]) {
    const startDate = `${datesRange[0].getDate()}/${datesRange[0].getMonth() + 1}/${datesRange[0].getFullYear()}`;
    const endDate = `${datesRange[1].getDate()}/${datesRange[1].getMonth() + 1}/${datesRange[1].getFullYear()}`;
    this.gaService.sendGoogleAnalyticsEvent('selectDateOrderHistory');
    this.getOrderHistory(startDate, endDate);
  }

  getOrderHistory(startDate: string = '', endDate: string = ''): void {
    if (startDate && endDate) {
      this.isLoading = true;
      this.spinnerText = 'Processing your request...';
    }
    this.orderHistoryService.getOrderHistory(this.memberId, startDate, endDate).subscribe(response => {
      this.paginationDetails.totalOrders = response.orders.length;
      this.orderItems = response.orders;
      this.filterOrders(this.paginationDetails.pageIndex, this.paginationDetails.pageSize);
      this.isLoading = false;
      this.spinnerText = null;
    }, error => {
      this.isLoading = false;
      this.spinnerText = null;
      this.notifications.push({severity: 'error', summary: error.json().Message});
    });
  }

  // filter orderList view
  filterOrders(page: number, rows: number): void {
    const startIndexItem = page * rows;
    this.filteredOrders = [];
    for (let i = startIndexItem; i < startIndexItem + rows; i++) {
      if (this.orderItems[i]) {
        this.filteredOrders.push(this.orderItems[i]);
      }
    }
  }

  /**
   * Open View order dialog
   * @param order
   */
  openViewOrderDialog(order: any): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.orderHistoryService.getOrderHistoryById(this.memberId, order.orderId).subscribe(response => {
      this.viewOrder = response;
      this.visible = true;
      this.dialogTitle = 'Your Order';
      this.countSubtotal(this.viewOrder.products);
      this.isLoading = false;
      this.spinnerText = null;
      this.gaService.sendGoogleAnalyticsEvent('viewOrderHistory');
    }, error => {
      this.isLoading = false;
      this.spinnerText = null;
      this.notifications.push({severity: 'error', summary: error.json().error_description});
    });
  }

  /**
   * Order History panel toggle handler
   * @param event - dom event object
   */
  onPanelToggle(event: { originalEvent: Event, collapsed: boolean }): void {
    this.panelToggled.emit(event);

    if (!event.collapsed) {
      this.gaService.sendGoogleAnalyticsEvent('orderHistoryOpen');
    }
  }

  onTrackPackageClick(): void {
    this.gaService.sendGoogleAnalyticsEvent('trackUrlOrderHistory');
  }

  /**
   *
   * @param order
   */
  openTrackOrderDialog(order: any): void {
    this.dialogTitle = 'Package Tracking';
    this.visible = true;
    this.viewOrder = order;
    this.gaService.sendGoogleAnalyticsEvent('trackOrderHistory');
  }

  /**
   * Sets default dates range for Payments list filtering
   */
  setPaymentsDatesRange() {
    const startDay = new Date();
    startDay.setMonth(startDay.getMonth() - 6);
    this.paymentsDatesRange = [startDay, new Date()];
  }

  /**
   * Invoked on paginator events and retreive new list of contacts
   * @param event - event from Primeng paginator
   */
  paginateOrdersDatatable(event: { page: number, rows: number }): void {
    this.paginationDetails.pageIndex = event.page;
    this.paginationDetails.pageSize = event.rows;
    this.filterOrders(this.paginationDetails.pageIndex, this.paginationDetails.pageSize);
  }
}
