import {ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Message} from 'primeng/primeng';
import {AddressService} from '../../../../../services/address.service';
import {WizardHelperService} from '../../../../../services/wizard-helper.service';
import {Address, ArpOrder} from '../../../../../classes/arp-order';
import {UserInfo} from '../../../../../classes/user-info';
import {Country} from '../../../../../classes/country';

@Component({
  selector: 'app-select-shipping-address-step',
  templateUrl: './select-shipping-address-step.component.html',
  styleUrls: ['../../shared/styles/select-address-step.component.css']
})
export class SelectShippingAddressStepComponent implements OnInit, OnDestroy {

  @Input() arpOrder: ArpOrder;
  @Input() country: Country;
  @Input() form: FormGroup;
  @Input() user: UserInfo;

  @Output() onClickNextBtn = new EventEmitter<string>();
  @Output() onSelectAddress = new EventEmitter<any>();

  addressItems: { address: Address, checked: boolean }[];
  addresses: Address[];
  changeDetectionInterval: any;
  dialogContentMaxWidth: number = window.innerWidth - 300;
  display = false;
  defaultAddressId: number;
  isLoading = false;
  notifications: Message[] = [];
  spinnerText: string;
  sub: Subscription;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(private addressService: AddressService, private ref: ChangeDetectorRef, private wizardHelperService: WizardHelperService) {
    this.changeDetectionInterval = setInterval(() => {
      ref.markForCheck();
    }, 50);
  }

  ngOnInit() {
    this.getAddressList();
    if (!this.form.controls.shippingAddressId) {
      this.addControl();
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // add "shippingAddressId" field to general form
  addControl(): void {
    this.form.addControl('shippingAddressId', new FormControl(this.defaultAddressId, Validators.required));
  }

  /**
   * Start editing address
   */
  addEditAddress(address: any): void {
    this.wizardHelperService.setAddressEditing(address);
    this.onClickNextBtn.emit('addEditShippingAddress');
  }

  // move to previous wizard step
  backToPreviousStep(): void {
    this.onClickNextBtn.emit('configureOrder');
  }

  // handler toggle btn event
  changeCheckedAddress(checked, address) {
    if (checked) {
      this.form.controls.shippingAddressId.setValue(address.address.addressId);
      this.addressItems.map(item => {
        item.checked = false;
        if (item.address.addressId === address.address.addressId) {
          item.checked = true;
        }
      });
    } else {
      this.form.controls.shippingAddressId.setValue('');
      this.addressItems.map(item => {
        item.checked = false;
      });
    }
  }

  /**
   * Updarte component addresses list and set shipping address id
   * @param addresses - addresses list
   */
  updateAddresses(addresses: Address[]): void {
    if (this.form.controls.shippingAddressId && this.form.controls.shippingAddressId.value) {
      const defaultAddress = addresses.find(item => item.addressId === this.form.controls.shippingAddressId.value);
      this.defaultAddressId = defaultAddress ? defaultAddress.addressId : null;
    } else {
      const defaultAddress = addresses.find(item => item.isDefaultShippingAddress === true);
      this.defaultAddressId = defaultAddress ? defaultAddress.addressId : null;
    }

    const addressItems = addresses.map(address => {
      if (address.addressId === this.defaultAddressId) {
        return {address, checked: true};
      } else {
        return {address, checked: false};
      }
    });
    this.addressItems = addressItems.slice();
  }

  // get address list from api
  getAddressList(): void {
    this.sub = this.wizardHelperService.addressListSubject.subscribe((addresses: Address[]) => {
      this.addresses = addresses;
      if (!addresses.length) {
        this.addEditAddress(null);
      } else {
        this.updateAddresses(addresses);
      }
    });
  }

  // remove address from address list by id
  removeAddress(addressId): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.addressService.removeAddress(this.user.memberId, addressId).subscribe(response => {
      this.wizardHelperService.setAddressList(response.addresses);
      this.isLoading = false;
      this.spinnerText = null;
      this.notifications.push({severity: 'success', summary: 'Address was deleted'});
    }, error => {
      this.isLoading = false;
      this.spinnerText = null;
      this.notifications.push({severity: 'error', summary: error.json().error_description});
    });
  }

  // move to next wizard step
  finishThisStep(): void {
    this.onClickNextBtn.emit('selectPaymentMethod');
  }
}
