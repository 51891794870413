import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Message} from 'primeng/primeng';
import {AffiliateService} from '../../../../services/affiliate.service';
import {Patterns} from '../../../../helpers/helper';
import {IMediaLibraryVideo} from '../../../../classes/media-library-video';
import {IEmailMessage} from '../../../../classes/email-message';
import {showError} from '../../../../helpers/form-helper';

@Component({
  selector: 'app-email-video-message-dialog',
  templateUrl: './email-video-message-dialog.component.html',
  styleUrls: ['./email-video-message-dialog.component.css']
})
export class EmailVideoMessageDialogComponent implements OnInit {
  @Input() memberId: string;
  @Input() emailDialogIsVisible: boolean;
  @Input() emailDialogVideo: IMediaLibraryVideo;
  @Output() onDialogHide = new EventEmitter();
  @Output() onMessageSent = new EventEmitter();

  isLoading = false;
  messageForm: FormGroup;
  messageFormIsSubmitted = false;
  notifications: Message[] = [];
  showError: Function = showError;

  constructor(
    private affiliateService: AffiliateService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.buildMessageForm();
  }

  /**
   * Build model driven Message form
   */
  buildMessageForm() {
    this.messageForm = this.fb.group(
      {
        'memberId': [this.memberId],
        'firstName': ['', Validators.required],
        'lastName': ['', Validators.required],
        'subject': [this.emailDialogVideo.Title, [Validators.required, Validators.maxLength(250)]],
        'message': ['', [Validators.required, Validators.maxLength(500)]],
        'emailAddress': ['', [Validators.required, Validators.pattern(Patterns.email)]],
        'TemplateID': [this.emailDialogVideo.TemplateID],
        'VideoID': [this.emailDialogVideo.VideoID]
      }
    );
  }

  /**
   * Send message to API
   * @param {Object} emailMessage
   */
  onSubmit(emailMessage: IEmailMessage): void {
    this.messageFormIsSubmitted = true;

    if (this.messageForm.valid) {
      this.isLoading = true;
      this.affiliateService.sendEmailMessage(emailMessage).subscribe(response => {
        this.isLoading = false;
        this.onDialogHide.emit('emailDialogIsVisible');
        this.onMessageSent.emit(null);
      }, error => {
        this.notifications.push({severity: 'error', summary: 'Try again, please.'});
        this.isLoading = false;
      });
    }
  }
}
