import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Message} from 'primeng/primeng';
import {Address, ArpOrder} from '../../../../../classes/arp-order';
import {Country} from '../../../../../classes/country';
import {AddressService} from '../../../../../services/address.service';
import {WizardHelperService} from '../../../../../services/wizard-helper.service';

@Component({
  selector: 'app-add-edit-shipping-address-step',
  templateUrl: './add-edit-shipping-address-step.component.html',
  styleUrls: ['./add-edit-shipping-address-step.component.css']
})
export class AddEditShippingAddressStepComponent implements OnInit, OnDestroy {
  @Input() arpOrder: ArpOrder;
  @Input() country: Country;
  @Input() form: FormGroup;
  @Input() user: any;

  @Output() onChangeStepTitle = new EventEmitter<string>();
  @Output() onClickNextBtn = new EventEmitter<string>();

  addressItems: Address[];
  editableAddress: Address = new Address();
  dialogContentMaxWidth: number = window.innerWidth - 300;
  isLoading = false;
  notifications: Message[] = [];
  spinnerText: string;
  sub: Subscription;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }


  constructor(private addressService: AddressService, private wizardHelperService: WizardHelperService) {
  }

  ngOnInit() {
    this.getAddressEditing();
    this.getAddressList();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // get address list from service
  getAddressList(): void {
    this.sub = this.wizardHelperService.addressListSubject.subscribe((addresses: Address[]) => {
      this.addressItems = addresses;
    });
  }

  // get address editing from service
  getAddressEditing(): void {
    this.editableAddress = this.wizardHelperService.addressEditing;
    this.editableAddress ? this.onChangeStepTitle.emit('Edit A Shipping Address') : this.onChangeStepTitle.emit('Add A Shipping Address');
  }

  /**
   * Finish adding or editing address process
   */
  endAddEditAddress(): void {
    this.wizardHelperService.setAddressEditing(null);
    this.onClickNextBtn.emit('selectShippingAddress');
  }
}
