import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from './config.service';
import {HttpService} from './http.service';
import {UserService} from './user.service';
import {UserInfo} from '../classes/user-info';
import {map} from 'rxjs/operators';

@Injectable()
export class LoginService {
  clientLoginData: {
    client_id: string;
    client_secret: string;
    grant_type: string;
  };
  private enteredEmail = '';
  sourceId: number;

  constructor(private httpService: HttpService, private userService: UserService, private configService: ConfigService) {
    this.clientLoginData = this.configService.getConfiguration().clientLoginData;
    this.sourceId = this.configService.getConfiguration().sourceId;
  }

  /**
   * Get entered email from login-form
   */
  getEnteredEmail() {
    return this.enteredEmail;
  }

  /**
   * Send post req to API for login user
   */
  login(loginData: { username: string, password: string }): Observable<UserInfo> {
    const body = `client_id=${this.clientLoginData.client_id
      }&client_secret=${this.clientLoginData.client_secret
      }&username=${loginData.username
      }&password=${loginData.password
      }&grant_type=${this.clientLoginData.grant_type}`;

    const contentType = 'application/x-www-form-urlencoded';

    return this.httpService.post(`login/`, 'account', body, null, contentType)
      .pipe(
        map((user: UserInfo) => {
          this.userService.setCurrentUser(user);
          return user;
        })
      );
  }

  /**
   * Send new password to API (forgot password flow)
   */
  sendNewPassword(custId: string, token: string, password: string): Observable<any> {
    const contentType = 'application/x-www-form-urlencoded';
    const body = `custId=${custId}&token=${token}&password=${password}`;
    return this.httpService.post(`profile/reset-password`, 'global', body, null, contentType);
  }

  /**
   * Send request to reset password (forgot password flow)
   */
  sendResetPasswordRequest(email: string, url: string) {
    return this.httpService.get(`profile/forgot-password?login=${email}&returnURL=${url}`, 'global');
  }

  /**
   * Set entered email in login-service
   */
  setEnteredEmail(email: string) {
    this.enteredEmail = email;
  }
}
