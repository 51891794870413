import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';

// Google Analytics global variable
declare let ga;

class GaEvent {
  category: string;
  label: string;
  action: string;

  constructor(category: string, label: string, action: string) {
    this.category = category;
    this.label = label;
    this.action = action;
  }
}

const gaEvents = {
  contactInformationOpen: new GaEvent('MyAccount', 'Contact Information', 'Open'),
  orderHistoryOpen: new GaEvent('MyAccount', 'Order History', 'Open'),
  arpOpen: new GaEvent('MyAccount', 'ARP', 'Open'),
  passwordOpen: new GaEvent('MyAccount', 'Password', 'Open'),
  updatePhoneOrEmail: new GaEvent('MyAccount', 'Contact Information', 'Update Phone/Email'),
  updateAddress: new GaEvent('MyAccount', 'Contact Information', 'Update Address'),
  addAddress: new GaEvent('MyAccount', 'Contact Information', 'Add Address'),
  viewOrderHistory: new GaEvent('MyAccount', 'Order History', 'View'),
  trackOrderHistory: new GaEvent('MyAccount', 'Order History', 'Track'),
  trackUrlOrderHistory: new GaEvent('MyAccount', 'Order History', 'Track URL'),
  selectDateOrderHistory: new GaEvent('MyAccount', 'Order History', 'Select Date'),
  addArpOrder: new GaEvent('MyAccount', 'ARP', 'Add Order'),
  editArpOrder: new GaEvent('MyAccount', 'ARP', 'Edit Order'),
  skipArpOrder: new GaEvent('MyAccount', 'ARP', 'Skip Order'),
  cancelArpOrder: new GaEvent('MyAccount', 'ARP', 'Cancel Order'),
  skipArpProduct: new GaEvent('MyAccount', 'ARP', 'Skip Product'),

  changeArpShippingAddress: new GaEvent('MyAccount', 'ARP', 'Change Shipping Address'),
  changeArpCreditCard: new GaEvent('MyAccount', 'ARP', 'Change Credit Card'),
  changePassword: new GaEvent('MyAccount', 'Password', 'Change Password'),
  forgotPassword: new GaEvent('MyAccount', 'Password', 'Forgot Password'),
  shareAndEarnCopyLink: new GaEvent('MyAccount', 'Share & Earn', 'Copy Link'),
  shareAndEarnOpenSendEmail: new GaEvent('MyAccount', 'Share & Earn', 'Open Send Email'),
  shareAndEarnSentEmail: new GaEvent('MyAccount', 'Share & Earn', 'Sent Email'),
  shareAndEarnDownloadPDF: new GaEvent('MyAccount', 'Share & Earn', 'Download PDF'),
};

@Injectable()
export class GoogleAnalyticsService {

  constructor(private configService: ConfigService) {
  }

  initGoogleAnalytics() {
    ga('create', this.configService.getConfiguration().googleAnalyticCode, 'auto');
    ga('send', 'pageview');
  }

  sendGoogleAnalyticsEvent(eventKey: string, eventValue?: number): void {
    const {category, label, action} = gaEvents[eventKey];
    eventValue
      ? ga('send', 'event', category, action, label, eventValue)
      : ga('send', 'event', category, action, label);
  }
}
