import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {Message} from 'primeng/primeng';
import {showError} from '../../../helpers/form-helper';

@Component({
  selector: 'app-create-password-form',
  templateUrl: './create-password-form.component.html',
  styleUrls: ['../forgot-password.component.css']
})
export class CreatePasswordFormComponent {
  @Input() memberId: string;
  @Input() nextStepName: string;
  @Input() token: string;
  @Output() onResetPasswordRequest = new EventEmitter<string>();

  createPasswordForm: FormGroup;
  isLoading = false;
  notifications: Message[] = [];
  password: string;
  showError: Function = showError;
  submitted = false;

  constructor(private fb: FormBuilder, private loginService: LoginService) {
    this.buildForm();
  }

  buildForm(): void {
    // TODO: move pattern to some file as was done in homedoc
    this.createPasswordForm = this.fb.group(
      {
        'password': [this.password, [
          Validators.required,
          Validators.maxLength(11),
          Validators.minLength(6),
          Validators.pattern(/^[a-zA-Z0-9]*$/)
        ]],
      }
    );
  }

  goToNextStep(): void {
    this.onResetPasswordRequest.emit(this.nextStepName);
  }

  onSubmit(): void {
    this.submitted = true;
    this.isLoading = true;
    if (this.createPasswordForm.valid && this.createPasswordForm.dirty) {
      this.loginService.sendNewPassword(this.memberId, this.token, this.createPasswordForm.controls['password'].value)
        .subscribe(res => {
          this.isLoading = false;
          this.goToNextStep();
        }, error => {
          this.isLoading = false;
          this.notifications.push({severity: 'error', summary: error.json().error_description});
        });
    }
  }
}
