import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {LogOutService} from './log-out.service';
import {ConfigService} from './config.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class HttpService {
  apiAccountUrl: string;
  apiCartUrl: string;
  apiGlobalUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService, private logOutService: LogOutService) {
    const apiUrlData = this.configService.getConfiguration().apiUrlData;
    this.apiAccountUrl = apiUrlData.host + apiUrlData.routes.account;
    this.apiCartUrl = apiUrlData.host + apiUrlData.routes.cart;
    this.apiGlobalUrl = apiUrlData.host + apiUrlData.routes.global;
  }

  /**
   * Custom wrappers for angular http service methods (get, post, put etc.)
   */
  get(reqUrl: string, apiName: string, token: string = '', contentType: string = 'application/json'): Observable<any> {
    const url = this.getApiUrl(apiName) + reqUrl;
    const reqOptions = this.getRequestOptions(contentType, `bearer ${token}`);
    return this.http.get(url, reqOptions).pipe(
      catchError((error) => this.handleError(error)));
  }

  post(reqUrl: string, apiName: string, body: any, token: string = '', contentType: string = 'application/json'): Observable<any> {
    const url = this.getApiUrl(apiName) + reqUrl;
    const reqOptions = this.getRequestOptions(contentType, `bearer ${token}`);
    if (contentType === 'application/json') {
      body = JSON.stringify(body);
    }
    return this.http.post(url, body, reqOptions).pipe(
      catchError((error) => this.handleError(error)));
  }

  put(reqUrl: string, apiName: string, body: any, token: string = '', contentType: string = 'application/json'): Observable<any> {
    const url = this.getApiUrl(apiName) + reqUrl;
    const reqOptions = this.getRequestOptions(contentType, `bearer ${token}`);
    if (contentType === 'application/json') {
      body = JSON.stringify(body);
    }
    return this.http.put(url, body, reqOptions).pipe(
      catchError((error) => this.handleError(error)));
  }

  /**
   * Form Headers object for request
   */
  private getHeaders(contentType: string = 'application/json', authorization: string) {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': contentType,
      'Authorization': authorization
    });
  }

  /**
   * Form Request Options object for request
   */
  private getRequestOptions(contentType?: string, authorization?: string) {
    return {headers: this.getHeaders(contentType, authorization)};
  }

  /**
   * Default error handling for http request
   */
  private handleError(error: any) {
    if (error.status === 401 && error.statusText === 'Unauthorized') {
      this.logOutService.setLogOutSubject(true);
    }
    return observableThrowError(error);
  }

  private getApiUrl(apiName: string) {
    if (apiName === 'account') {
      return this.apiAccountUrl;
    } else if (apiName === 'cart') {
      return this.apiCartUrl;
    } else if (apiName === 'global') {
      return this.apiGlobalUrl;
    }
  }
}
