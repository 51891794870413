import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ArpOrder} from '../../../../../classes/arp-order';
import {Country} from '../../../../../classes/country';
import {WizardHelperService} from '../../../../../services/wizard-helper.service';

@Component({
  selector: 'app-products-management',
  templateUrl: './products-management.component.html',
  styleUrls: ['./products-management.component.css']
})
export class ProductsManagementComponent implements OnInit {
  @Input() country: Country;
  @Input() existingOrder: ArpOrder;

  @Output() onCloseProductsManagement: EventEmitter<string> = new EventEmitter(null);
  @Output() onProductListUpdated: EventEmitter<ArpOrder> = new EventEmitter();

  isLoading = false;
  order: ArpOrder;
  spinnerText: string;
  subtotal: number;

  constructor(
    private wizardHelperService: WizardHelperService
  ) {
  }

  ngOnInit() {
    this.order = JSON.parse(JSON.stringify(this.existingOrder));
  }

  // add selected products to order
  addSelectedProduct(product): void {
    product.quantity = 1;
    this.order.products.push(product);
    this.order.products = this.order.products.slice();
  }

  // remove selected product
  removeSelectedProduct(product): void {
    const removedProductIndex: number = this.order.products.findIndex(item => item.productId === product.productId);
    this.order.products.splice(removedProductIndex, 1);
    // need to call change detection in child datatable
    this.wizardHelperService.setDeletedProductFromSelectList(product);
    this.order.products = this.order.products.slice();
  }


  // send updated order to service
  updateOrders(): void {
    this.onProductListUpdated.emit(this.order);
    this.onCloseProductsManagement.emit();
  }
}
