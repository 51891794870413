import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeResourceUrl'
})
@Injectable()
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(resourceUrl) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(resourceUrl);
  }
}
