import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-header-dropdown',
  templateUrl: './header-dropdown.component.html',
  styleUrls: ['./header-dropdown.component.scss']
})
export class HeaderDropdownComponent {
  @Input() set show(v: boolean) {
    this._show = v;
    this.input = true;
  }

  @Output() showChange: EventEmitter<boolean> = new EventEmitter();
  @Output() logout: EventEmitter<null> = new EventEmitter();

  private _show: boolean;
  private input = false;

  constructor(private elementRef: ElementRef) {
  }

  get show(): boolean {
    return this._show;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if (!clickedInside && !this.input) {
      this.showChange.emit(false);
    }

    this.input = false;
  }
}
