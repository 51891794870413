export class ArpOrder {
  memberId: number;
  orderId: number;
  countryId: number;
  products: Product[];
  dayOfMonthToShip: number;
  interval: number;
  autoApplyVitaPoints: boolean;
  shippingAddress?: Address;
  shippingAddressId: number;
  billingAddress?: Address;
  billingAddressId: number;
  payment: Payment;
  previewOnly: boolean;
  cardId?: number;
  estimatedTax?: number;
  estimatedShipping?: number;

  constructor(
    products: Product[],
    dayOfMonthToShip: number,
    interval: number,
    autoApplyVitaPoints: boolean,
    shippingAddressId: number,
    billingAddressId: number,
    payment: Payment,
    memberId: number = 0,
    orderId: number = 0,
    countryId: number = 1
  ) {
    this.products = products;
    this.shippingAddressId = shippingAddressId;
    this.billingAddressId = billingAddressId;
    this.payment = payment;
  }
}

export class Address {
  addressName: string = null;
  address1: string = null;
  address2 = '';
  postalCode: string = null;
  city: string = null;
  state: string = null;
  countryId: number = null;
  isDefaultBillingAddress = false;
  isDefaultShippingAddress = false;
  addressId = 0;

  constructor() {
  }
}

export interface Product {
  productId: number;
  itemNumber: string;
  productName: string;
  price: number;
  quantity: number;
  discounts?: any;
  cartImage: string;
  csvKeywords: any;
}

export class Payment {
  nameOnCard: string = null;
  cardNumber: string = null;
  cardType: string = null;
  cardExpMonth: string = null;
  cardExpYear: string = null;
  cardCvvCode: string = null;
  memberId? = 0;
  cardId?;
  paymentToken?: string = null;

  constructor() {
  }
}
