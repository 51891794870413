import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Address, ArpOrder, Payment, Product} from '../classes/arp-order';


@Injectable()
export class WizardHelperService {
  private _addressEditing: Address;
  private _removedProductSubject: Subject<Product> = new Subject();
  private _addressListSubject: BehaviorSubject<Address[]> = new BehaviorSubject([]);
  private _cardListSubject: BehaviorSubject<Payment[]> = new BehaviorSubject([]);
  private _orderListSubject: BehaviorSubject<ArpOrder[]> = new BehaviorSubject([]);

  constructor() {
  }

  // to updating select product list in order
  public setDeletedProductFromSelectList(product): void {
    this._removedProductSubject.next(product);
  }

  get removedProductSubject(): Subject<Product> {
    return this._removedProductSubject;
  }

  // to updating address list
  public setAddressList(addresses): void {
    this._addressListSubject.next(addresses);
  }

  get addressListSubject(): Subject<Address[]> {
    return this._addressListSubject;
  }

  // to updating card list
  public setCardList(cardList): void {
    this._cardListSubject.next(cardList);
  }

  get cardListSubject(): Subject<Payment[]> {
    return this._cardListSubject;
  }

  // to updating order list
  public setOrderList(orders): void {
    this._orderListSubject.next(orders);
  }

  get orderListSubject(): Subject<ArpOrder[]> {
    return this._orderListSubject;
  }

  // to controlling address editing
  public setAddressEditing(address: Address): void {
    this._addressEditing = JSON.parse(JSON.stringify(address));
  }

  get addressEditing(): Address {
    return this._addressEditing;
  }

}
