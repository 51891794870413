import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {PaymentService} from '../../../../../services/payment.service';
import {WizardHelperService} from '../../../../../services/wizard-helper.service';
import {Message, SelectItem} from 'primeng/primeng';
import {Country} from '../../../../../classes/country';

@Component({
  selector: 'app-add-edit-payment-method-step',
  templateUrl: './add-edit-payment-method-step.component.html',
  styleUrls: ['./add-edit-payment-method-step.component.css']
})

export class AddEditPaymentMethodStepComponent implements OnInit {
  @Input() country: Country;
  @Input() form: FormGroup;
  @Input() memberId: string;

  @Output() onClickNextBtn = new EventEmitter<string>();
  @Output() onChangeStepTitle = new EventEmitter<string>();

  creditCardsTypes: SelectItem [] = [];
  backStepName: string;
  dialogContentMaxWidth: number = window.innerWidth - 300;
  isLoading: boolean;
  notifications: Message[] = [];

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(private paymentService: PaymentService, private wizardHelperService: WizardHelperService) {
  }

  ngOnInit() {
    this.getPaymentOptions();
    this.getCardList();
  }

  // defined next  or preview step after filling out creditCardForm
  changeStep(): void {
    this.onClickNextBtn.emit(this.backStepName);
  }

  // get all cards for this memberId and set card list to object
  getCardList(): void {
    this.wizardHelperService.cardListSubject.subscribe(cards => {
      this.backStepName = cards && cards.length ? 'selectPaymentMethod' : 'selectShippingAddress';
    });
  }

  // get credit card options for add/edit card form
  getPaymentOptions(): void {
    this.paymentService.getCardTypes().subscribe(response => {
      const creditCardsTypes = response.countries[2].cardTypes;
      for (let i = 0; i < creditCardsTypes.length; i++) {
        this.creditCardsTypes.push({value: creditCardsTypes[i].value.toString(), label: creditCardsTypes[i].displayName});
      }
    }, error => {
      this.notifications.push({severity: 'error', summary: error.json().error_description});
    });
  }

  // move to next wizard step
  finishThisStep(): void {
    this.onClickNextBtn.emit('selectBillingAddress');
  }

}
