export interface HeaderConfiguration {
  label: string;
  value: string;
  active: boolean;
  secondary?: { label: string, value: string; active: boolean } [];
  collapsed?: boolean;
}

export enum Pages {
  ACCOUNT_MANAGEMENT = 'ACCOUNT MANAGEMENT',
  AFFILIATE_OFFICE = 'AFFILIATE OFFICE',
  WELLNESS_STORE = 'WELLNESS STORE',
}

export const headerConfiguration: HeaderConfiguration[] = [
  {label: Pages.ACCOUNT_MANAGEMENT, value: '/main/account-management', active: false},
  {label: Pages.AFFILIATE_OFFICE, value: '/main/affiliate-office', active: false},
  {
    label: Pages.WELLNESS_STORE, value: '/wellness-store', active: false,
    collapsed: true,
    secondary: [
      {label: 'Feed', value: '/feed', active: false},
      {label: 'Blog', value: '/blog', active: false},
      // {label: 'Gift card', path: '/gift-card', active: false},
      {label: 'Site Settings', value: '/site-settings', active: false},
      {label: 'User Agreement', value: '/super-admin', active: false}
    ]
  }
];

export function  getHeaderConfiguration(exclude: string[]): HeaderConfiguration[] {
  return headerConfiguration
    .filter((page: HeaderConfiguration) => !exclude.includes(page.value))
    .map(page => {
      if (page.secondary) {
        page.secondary = page.secondary.filter(subpage => !exclude.includes(subpage.value));
      }

      return page;
    });
}
