import {Component, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from './services/google-analytics.service';
import {StoreParamsService} from './services/store-params.service';
import {UserService} from './services/user.service';
import {UserInfo} from './classes/user-info';
import {LocalStorage} from './helpers/local-storage.decorator';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  @LocalStorage() user: UserInfo;

  constructor(
    private gaService: GoogleAnalyticsService,
    private storeParamsService: StoreParamsService,
    private router: Router,
    private renderer: Renderer2,
    public userService: UserService
  ) {
  }

  ngOnInit() {
    this.setBodyThemeClass(this.storeParamsService.getQueryParameterByName('siteId'));
    // this.checkUserIsLogged();
    this.gaService.initGoogleAnalytics();

    if (this.user) {
      this.userService.setCurrentUser(this.user);
    }
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  checkUserIsLogged(): void {
    const currentUser = this.userService.getCurrentUser();
    if (!currentUser && location.pathname !== '/forgot-password' && location.pathname !== '/login') {
      this.router.navigate(['/login']);
    }
  }

  /**
   Allows to scroll pages to top after their refreshing
   */
  onRouterDeactivate(): void {
    this.renderer.setProperty(document.body, 'scrollTop', 0);
  }

  /**
   * Get passed in query params site id (site user came from) and
   * set specify class to body tag for styling child elements
   * according to design that site
   * See assets/styles/themes/ directory
   */
  setBodyThemeClass(siteId: string = '1') {
    document.querySelector('body').classList.remove(`theme-site-id-1`); // remove default class
    document.querySelector('body').classList.add(`theme-site-id-${siteId}`); // apply actual class
  }
}

