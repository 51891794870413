import {Address} from '../classes/arp-order';
import {GiftCard} from '../modules/account-managment/refer-a-friend/refer-a-friend.component';

export class UserInfo {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  memberId: string;
  billingAddress?: Address;
  shippingAddress?: Address;
  billingAddressId?: string;
  shippingAddressId?: string;
  customerLevel: string;
  customerRank: string;
  isAffiliate: string | boolean;
  isItbo: string | boolean;
  isPremierMember: string | boolean;
  memberlevel: string;
  returnUrl: string;
  vitaPointsBalance: string;
  token: string;
  giftCard?: GiftCard;
}
