import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Dialog, Message} from 'primeng';
import {Address, ArpOrder} from '../../../../classes/arp-order';
import {UserInfo} from '../../../../classes/user-info';

@Component({
  selector: 'app-arp-order-editing',
  templateUrl: './arp-order-editing.component.html',
  styleUrls: ['./arp-order-editing.component.css']
})
export class ArpOrderEditingComponent implements OnInit {
  @ViewChild('dialog', {static: true}) dialog: Dialog;
  @Input() editableOrder;
  @Input() country;
  @Input() form;
  @Input() isProductManagement: boolean;
  @Input() isPaymentManagement: boolean;
  @Input() typeOfAddress: boolean;
  @Input() visible: boolean;
  @Input() user: UserInfo;

  @Output() onCloseEditingDialog: EventEmitter<null> = new EventEmitter(null);
  @Output() onProductListUpdated: EventEmitter<ArpOrder> = new EventEmitter();
  @Output() onShowNotification: EventEmitter<any> = new EventEmitter();

  isAddingEditingAddress: boolean;
  dialogCenterVisible = true; // needed to center dialog, issue: https://github.com/primefaces/primeng/issues/2795
  dialogContentHeight: number = window.innerHeight - 300;
  dialogProductManagemenContentHeight = 500;
  editOrderForm: FormGroup;
  editableAddress: Address;
  notifications: Message[] = [];
  title: string;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.dialogContentHeight = event.target.innerHeight - 300;
  }

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.buildForm();

    this.title = this.typeOfAddress ? 'Address Management' : 'Payment Management';
    if (this.isProductManagement) {
      this.title = 'Product Management';
    }
  }

  buildForm(): void {
    this.editOrderForm = this.formBuilder.group({
      memberId: [this.editableOrder.memberId],
      orderId: [this.editableOrder.orderId],
      countryId: [this.editableOrder.countryId],
      products: [this.editableOrder.products],
      dayOfMonthToShip: [this.editableOrder.dayOfMonthToShip],
      interval: [this.editableOrder.interval],
      autoApplyVitaPoints: [this.editableOrder.autoApplyVitaPoints],
      shippingAddressId: [this.editableOrder.shippingAddress.addressId],
      billingAddressId: [this.editableOrder.billingAddress.addressId],
      payment: [this.editableOrder.payment],
      previewOnly: [false]
    });
  }

  addEditAddress(event): void {
    this.isAddingEditingAddress = event;
    this.centerDialog();
  }

  selectAddresToEditing(event): void {
    this.editableAddress = event;
  }

  closeDialog(): void {
    this.editableOrder = null;
    this.typeOfAddress = null;
    this.onCloseEditingDialog.emit();
  }

  changeAddress(): void {
    this.onShowNotification.emit({});
    this.onCloseEditingDialog.emit();
  }

  /**
   * Needed to center dialog with new width
   * Workaround for issue: https://github.com/primefaces/primeng/issues/2795
   */
  centerDialog(): void {
    // setTimeout(this.dialog.center.bind(this.dialog), 0);
  }
}
