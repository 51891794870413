import {Observable, throwError as observableThrowError} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {LocalStorage} from '../helpers/local-storage.decorator';
import {WELLNESS_CACHE, WELLNESS_STORE} from '../modules/wellness-store/services/wellness-store.api.service';
import {Router} from '@angular/router';

export const TokenInterceptorFactory = (router, config) => {
  return new TokenInterceptor(router, config);
};

export class TokenInterceptor implements HttpInterceptor {
  @LocalStorage() user;

  constructor(private router: Router, private config: any) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let customReq = null;
    const token = this.user ? this.user.token : null;

    if (token && request.url.includes(WELLNESS_STORE)) {
      customReq = request.clone({
        headers: request.headers
          .append('Authorization', `bearer ${token}`)
      });
    }

    if (request.url.includes(WELLNESS_CACHE)) {
      customReq = request.clone({
        headers: request.headers
          .append('Authorization', `${this.config.getConfiguration().clearCacheToken}`)
      });
    }

    return next
      .handle(customReq || request)
      .pipe(
        catchError((error: Response) => {
          if (error.status === 500) {
            this.router.navigate(['/login']);
          }
          return observableThrowError(error);
        })
      );
  }
}
