import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'arpOrderOptions'
})

export class ArpOrderOptionsPipe implements PipeTransform {
  transform(optionValue: number, options: any): string {
    return options[optionValue];
  }
}
