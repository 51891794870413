import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MyAccountQueryParams} from '../classes/my-account-query-params';


@Injectable()
export class StoreParamsService {
  private params: MyAccountQueryParams = new MyAccountQueryParams();

  constructor(private route: ActivatedRoute) {
  }

  getQueryParameterByName(name: string): string {
    const url = window.location.href;
    name = name.replace(/[[]]/g, '\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);

    if (!results) {
      return localStorage.getItem(name) ? localStorage.getItem(name) : undefined;
    }
    ;

    if (!results[2]) {
      return undefined;
    }

    const value: string = decodeURIComponent(results[2].replace(/\+/g, ' '));
    this.setQueryParameterByName(name, value);
    return value;
  }

  setQueryParameterByName(name: string, value: string): void {
    localStorage.setItem(name, value);
  }
}

