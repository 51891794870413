import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Message, SelectItem} from 'primeng/primeng';
import {ArpOrderService} from '../../../../../services/arp-order.service';
import {GoogleAnalyticsService} from '../../../../../services/google-analytics.service';
import {PaymentService} from '../../../../../services/payment.service';
import {WizardHelperService} from '../../../../../services/wizard-helper.service';

@Component({
  selector: 'app-payment-management',
  templateUrl: './payment-management.component.html',
  styleUrls: ['./payment-management.component.css']
})
export class PaymentManagementComponent {
  @Input() form: FormGroup;
  @Output() onClosePaymentManagement: EventEmitter<string> = new EventEmitter(null);
  @Output() onShowNotification = new EventEmitter<any>();

  creditCardsTypes: SelectItem [] = [];
  isLoading = false;
  notifications: Message[] = [];
  spinnerText: string;
  dialogContentMaxWidth: number = window.innerWidth - 300;

  /**
   * Window resize handler for opened dialog
   */
  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(
    private arpOrderService: ArpOrderService,
    private gaService: GoogleAnalyticsService,
    private paymentService: PaymentService,
    private wizardHelperService: WizardHelperService
  ) {
    this.getCreditCards();
  }

  // send updated order to service and update general order list
  sendUpdatedOrder(): void {
    this.notifications = [];
    this.loading();

    this.arpOrderService.editArpOrder(this.form.value).subscribe(
      response => {
        this.wizardHelperService.setOrderList(response.arpOrders); // update general order list

        this.gaService.sendGoogleAnalyticsEvent('changeArpCreditCard');
        this.onShowNotification.emit({error: false, message: 'Payment was changed.'});
        this.onClosePaymentManagement.emit();

        this.loading(false);
      },

      error => {
        if (error.json().error === 'AEC0053') {
          this.notifications.push({
            severity: 'error', summary: 'There was an error processing the credit card provided. Please update and try again.'
          });
        } else {
          this.notifications.push({severity: 'error', summary: error.json().error_description});
        }

        this.loading(false);
      }
    );
  }

  private getCreditCards(): void {
    this.paymentService.getCardTypes().subscribe(response => {
      const creditCardsTypes = response.countries[2].cardTypes;
      for (let i = 0; i < creditCardsTypes.length; i++) {
        this.creditCardsTypes.push({value: creditCardsTypes[i].value.toString(), label: creditCardsTypes[i].displayName});
      }
    }, error => {
      this.notifications.push({severity: 'error', summary: error.json().error_description});
    });
  }

  private loading(enable: boolean = true): void {
    if (enable) {
      this.isLoading = true;
      this.spinnerText = 'Processing your request...';
    } else {
      this.isLoading = false;
      this.spinnerText = null;
    }
  }
}
