import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Message} from 'primeng/primeng';
import {ArpOrder} from '../../../../classes/arp-order';
import {ArpOrderService} from '../../../../services/arp-order.service';
import {GoogleAnalyticsService} from '../../../../services/google-analytics.service';
import {showError} from '../../../../helpers/form-helper';
import {WizardHelperService} from '../../../../services/wizard-helper.service';
import {Country} from '../../../../classes/country';
import {UserInfo} from '../../../../classes/user-info';

@Component({
  selector: 'app-arp-order',
  templateUrl: './arp-order.component.html',
  styleUrls: ['./arp-order.component.css']
})
export class ArpOrderComponent implements OnInit {
  @Input() country: Country;
  @Input() user: UserInfo;
  @Input() orderList: ArpOrder[];

  @Output() onToggleWizardDialog = new EventEmitter<boolean>();

  editArpOrderForm: FormGroup;
  editableOrder: ArpOrder;
  isLoading: boolean;
  isCreditCardError = false;
  isSkippingConfirmation = false;
  isCancelingConfirmation = false;
  isProductManagement = false;
  isPaymentManagement = false;
  notifications: Message[] = [];
  orderEditingDialogIsOpen: boolean;
  productsListMode = 'view';
  showError: Function = showError;
  typeOfAddress: string;
  skippingOrderId: number;
  cancelingOrderId: number;
  spinnerText: string;

  constructor(
    private arpOrderService: ArpOrderService,
    private gaService: GoogleAnalyticsService,
    private fb: FormBuilder,
    private wizardHelperService: WizardHelperService
  ) {
  }

  ngOnInit() {
  }

  onAddOrderClick() {
    this.onToggleWizardDialog.emit(true);
  }

  /**
   * Cancel arp order by id
   * @param orderId - order id
   */
  cancelOrder(): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';

    // find index of canceled arp order
    const indexOfCanceledOrder = this.orderList.findIndex(item => {
      return item.orderId === this.cancelingOrderId;
    });
    this.arpOrderService.cancelArpOrder(this.user.memberId, this.cancelingOrderId).subscribe(response => {
      this.orderList.splice(indexOfCanceledOrder, 1);
      this.wizardHelperService.setOrderList(this.orderList);
      this.isLoading = false;
      this.spinnerText = null;
      this.notifications.push({severity: 'success', summary: 'Order was canceled'});
      this.cancelingOrderId = null;
      this.isCancelingConfirmation = false;
    }, error => {
      this.isLoading = false;
      this.spinnerText = null;
      this.notifications.push({severity: 'error', summary: error.json().error_description});
    });
  }

  // close confirmation dialog
  closeConfirmationDialog(): void {
    this.isSkippingConfirmation = false;
    this.isCancelingConfirmation = false;
    this.skippingOrderId = null;
    this.cancelingOrderId = null;
  }

  // show canceling confirmation dialog
  isCancelingConfirmationShow(orderId): void {
    this.isCancelingConfirmation = true;
    this.cancelingOrderId = orderId;
  }

  // show skipping confirmation dialog
  isSkippingConfirmationShow(orderId): void {
    this.isSkippingConfirmation = true;
    this.skippingOrderId = orderId;
  }

  /**
   * Open address management dialog
   * @param editingOrder - order object
   */
  openAddressManagement(editingOrder: { order: ArpOrder, typeOfAddress: string }): void {
    this.resetArpOrderEditing();
    this.orderEditingDialogIsOpen = true;
    this.editableOrder = editingOrder.order;
    this.typeOfAddress = editingOrder.typeOfAddress;
  }

  openProductManagement(editingOrder) {
    this.typeOfAddress = null;
    this.isProductManagement = true;
    // this.resetArpOrderEditing();
    this.orderEditingDialogIsOpen = true;
    this.editableOrder = editingOrder;
  }

  /**
   * Open address management dialog
   * @param editingOrder - order object
   */
  openPaymentManagement(editingOrder: ArpOrder): void {
    this.resetArpOrderEditing();
    this.orderEditingDialogIsOpen = true;
    this.editableOrder = editingOrder;
    this.isPaymentManagement = true;
  }

  /**
   * Open order options editing form
   * @param order - order object
   */
  openEditOrderOptions(order: ArpOrder): void {
    this.editableOrder = order;
    this.productsListMode = 'edit';
    this.buildForm();
  }

  buildForm(): void {
    this.editArpOrderForm = this.fb.group({
      memberId: this.editableOrder.memberId,
      orderId: this.editableOrder.orderId,
      countryId: this.editableOrder.countryId,
      products: this.fb.array(
        this.editableOrder.products.map(product => this.fb.group(product))
      ),
      dayOfMonthToShip: this.editableOrder.dayOfMonthToShip,
      interval: this.editableOrder.interval,
      autoApplyVitaPoints: this.editableOrder.autoApplyVitaPoints,
      shippingAddressId: this.editableOrder.shippingAddress.addressId,
      billingAddressId: this.editableOrder.billingAddress.addressId,
      payment: this.editableOrder.payment,
      previewOnly: false
    });
  }

  // update arp order
  saveArpOrder(): void {
    this.isCreditCardError = false;
    this.notifications = [];
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.arpOrderService.editArpOrder(this.editArpOrderForm.value)
      .subscribe(({arpOrders}: { arpOrders: ArpOrder[] }) => {
          this.sendGaEvent(arpOrders);
          this.wizardHelperService.setOrderList(arpOrders);
          this.resetArpOrderEditing();
          this.isLoading = false;
          this.spinnerText = null;
          this.notifications.push({severity: 'success', summary: 'Order was updated'});
        }, error => {
          if (error.json().error === 'AEC0053') {
            this.isCreditCardError = true;
          } else {
            this.notifications.push({severity: 'error', summary: error.json().error_description});
          }
          this.isLoading = false;
          this.spinnerText = null;
        }
      );
  }

  /**
   * Send event to GA with updated order Estimated Total
   * @param arpOrders - updated arp orders list
   */
  sendGaEvent(arpOrders: ArpOrder[]): void {
    // Count estimated total sum and send it to Google Analytics
    const updatedOrder: ArpOrder = arpOrders.find(order => order.orderId === this.editArpOrderForm.value.orderId);

    if (updatedOrder) {
      const total =
        updatedOrder.estimatedShipping +
        updatedOrder.estimatedTax +
        updatedOrder.products
          .map(product => product.price * product.quantity)
          .reduce((prevValue, currValue) => prevValue + currValue);

      this.gaService.sendGoogleAnalyticsEvent('editArpOrder', Math.floor(total * 100) / 100);
    }
  }

  // cancel editing arp order
  resetArpOrderEditing(): void {
    this.editableOrder = null;
    this.editArpOrderForm = null;
    this.productsListMode = 'view';
  }

  // skip next order
  skipNextOrder() {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.arpOrderService.skipArpOrder(this.user.memberId, this.skippingOrderId).subscribe(response => {
      this.arpOrderService.getArpOrders(this.user.memberId).subscribe(orders => {
        this.wizardHelperService.setOrderList(orders.arpOrders);
        this.isLoading = false;
        this.spinnerText = null;
        this.notifications.push({severity: 'success', summary: 'Next shipment date was changed'});
        this.skippingOrderId = null;
        this.isSkippingConfirmation = false;
      }, error => {
        this.isLoading = false;
        this.spinnerText = null;
        this.notifications.push({severity: 'error', summary: error.json().error_description});
      });
    }, error => {
      this.isLoading = false;
      this.spinnerText = null;
      this.notifications.push({severity: 'error', summary: error.json().error_description});
    });
  }

  // show success message after updating order
  onProductListApply(order): void {
    this.editArpOrderForm.removeControl('products');
    this.editArpOrderForm.addControl(
      'products',
      this.fb.array(
        order.products.map(product => this.fb.group(product))
      )
    );
  }

  showNotification(event): void {
    this.notifications = [];
    if (event.error) {
      this.notifications.push({severity: 'error', summary: event.message});
    } else {
      this.notifications.push({severity: 'success', summary: event.message});
    }
  }

  onCloseDialog(): void {
    this.isPaymentManagement = false;
    this.orderEditingDialogIsOpen = false;
    this.isProductManagement = false;
  }
}
