import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {HttpService} from './http.service';
import {ConfigService} from './config.service';
import {UserService} from '../services/user.service';

@Injectable()
export class AddressService {

  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
    private userService: UserService
  ) {
  }

  get userToken(): string {
    if (this.userService.getCurrentUser()) {
      return this.userService.getCurrentUser().token;
    }
  }

  // Get all addresses
  getAllAddresses(memberId) {
    return this.httpService.get(`address/${memberId}`, 'global', this.userToken);
  }

  // Get address by id
  getAddressById(memberId, addressIds: number[], userToken: string) {
    const forkJoinArray: Observable<any>[] = [];
    for (const addressId of addressIds) {
      const observable = this.httpService.get(`address/${memberId}?addressId=${addressId}`, 'global', userToken);
      forkJoinArray.push(observable);
    }
    return forkJoin(forkJoinArray);
  }

  // Remove address
  removeAddress(memberId, addressId) {
    return this.httpService.put(`address/${memberId}?addressId=${addressId}`, 'global', null, this.userToken);
  }

  // Update exist or create new addresses
  updateAddressList(address: any) {
    const memberId = this.userService.getCurrentUser().memberId;
    address.addressId = address.addressId ? address.addressId : 0;
    return this.httpService.post(`address/${memberId}`, 'global', address, this.userToken);
  }
}
