import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {UserService} from './user.service';
import {UserInfo} from '../classes/user-info';

@Injectable()
export class ProfileService {
  userToken: string;

  constructor(private httpService: HttpService, private userService: UserService) {
    if (this.userService.getCurrentUser()) {
      this.userToken = this.userService.getCurrentUser().token;
    }
  }

  /**
   * Get profile info from API
   */
  retrieveProfile(memberId: string): Observable<UserInfo> {
    return this.httpService.get(`profile/${memberId}`, 'account', this.userToken);
  }
}
