import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Message} from 'primeng/primeng';
import {ArpOrderService} from '../../../../../services/arp-order.service';
import {GoogleAnalyticsService} from '../../../../../services/google-analytics.service';
import {ArpOrder} from '../../../../../classes/arp-order';
import {Country} from '../../../../../classes/country';
import {UserInfo} from '../../../../../classes/user-info';

@Component({
  selector: 'app-review-your-order-step',
  templateUrl: './review-your-order-step.component.html',
  styleUrls: ['./review-your-order-step.component.css']
})
export class ReviewYourOrderStepComponent implements OnInit {
  @Input() arpOrder: ArpOrder;
  @Input() country: Country;
  @Input() form: FormGroup;
  @Input() user: UserInfo;

  @Output() onClickNextBtn = new EventEmitter<string>();
  @Output() onSubmitOrder = new EventEmitter<ArpOrder[]>();

  editArpOrderForm: FormGroup;
  estimatedTotal = 0;
  isLoading: boolean;
  isCreditCardError = false;
  notifications: Message[] = [];
  order: ArpOrder;
  productsListMode = 'view';
  spinnerText: string;

  constructor(
    private arpOrderService: ArpOrderService,
    private fb: FormBuilder,
    private gaService: GoogleAnalyticsService
  ) {
  }

  ngOnInit() {
    this.order = JSON.parse(JSON.stringify(this.form.value));
    this.submitArpOrder(true);
  }

  // move to previous wizard step
  backToPreviousStep(): void {
    this.onClickNextBtn.emit('selectBillingAddress');
  }

  goToSelectPaymentStep(): void {
    this.onClickNextBtn.emit('selectPaymentMethod');
  }

  /**
   * Open order options editing form
   * @param order - order object
   */
  openEditOrderOptions(order: ArpOrder): void {
    this.editArpOrderForm = this.fb.group({
      products: this.fb.array(
        this.form.value.products.map(product => this.fb.group(product))
      ),
      dayOfMonthToShip: this.form.value.dayOfMonthToShip,
      interval: this.form.value.interval,
      autoApplyVitaPoints: this.form.value.autoApplyVitaPoints,
    });
    this.productsListMode = 'edit';
  }

  resetArpOrderOptionsEditing(): void {
    this.editArpOrderForm = null;
    this.productsListMode = 'view';
  }

  saveArpOrderOptions(): void {
    this.form.controls.products.setValue(this.editArpOrderForm.value.products);
    this.form.controls.dayOfMonthToShip.setValue(this.editArpOrderForm.value.dayOfMonthToShip);
    this.form.controls.interval.setValue(this.editArpOrderForm.value.interval);
    this.form.controls.autoApplyVitaPoints.setValue(this.editArpOrderForm.value.autoApplyVitaPoints);
    this.submitArpOrder(true);
    this.resetArpOrderOptionsEditing();
  }

  totalUpdated(newValue: number): void {
    this.estimatedTotal = newValue;
  }

  cancelSubmitOrder() {
    this.onSubmitOrder.emit(null);
  }

  // submit arp order
  submitArpOrder(previewOnly: boolean): void {
    this.notifications = [];
    this.isCreditCardError = false;
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.arpOrderService
      .addArpOrder(this.user.memberId, this.user.billingAddress.countryId, this.form.value, previewOnly).subscribe(response => {
      this.isLoading = false;
      this.spinnerText = null;
      if (!previewOnly) {
        this.notifications.push({severity: 'success', summary: 'Your order was successfully added.'});
        this.onSubmitOrder.emit(response.arpOrders);
        this.gaService.sendGoogleAnalyticsEvent('addArpOrder', Math.floor(this.estimatedTotal * 100) / 100);
      } else {
        this.order = response.arpOrders[0];
      }
    }, error => {
      this.isLoading = false;
      this.spinnerText = null;
      if (error.json().error === 'AEC0052') {
        this.isCreditCardError = true;
      } else {
        this.notifications.push({severity: 'error', summary: error.json().error_description});
      }
    });
  }
}
