import {Routes} from '@angular/router';

import {MainComponent} from './main.component';
import {AccountManagmentComponent} from '../account-managment/account-managment.component';
import {AffiliateOfficeComponent} from '../affiliate-office/affiliate-office.component';

export const mainRoutes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'account-management',
        pathMatch: 'full'
      },
      {
        path: 'account-management',
        component: AccountManagmentComponent,
      },
      {
        path: 'affiliate-office',
        component: AffiliateOfficeComponent,
      }
    ]
  }
];
