import {Patterns} from '../../../../../helpers/helper';
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Message, SelectItem} from 'primeng/primeng';
import {AddressService} from '../../../../../services/address.service';
import {WizardHelperService} from '../../../../../services/wizard-helper.service';
import {Country} from '../../../../../classes/country';
import {Address} from '../../../../../classes/arp-order';


@Component({
  selector: 'app-add-edit-address',
  templateUrl: './add-edit-address.component.html',
  styleUrls: ['./add-edit-address.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddEditAddressComponent implements OnInit {
  @Input() currentAddress: Address;
  @Input() country: Country = null;
  @Input() typeOfAddress: string = null;
  @Input() isEditingOrder: boolean;
  @Output() onEndAddEditAddress = new EventEmitter<null>();
  @Output() onOpenForm = new EventEmitter<any>();

  addressForm: FormGroup;
  isLoading = false;
  notifications: Message[] = [];
  postalCodeMask: string;
  states: SelectItem[] = [];
  spinnerText: string;

  constructor(
    private addressService: AddressService,
    private formBuilder: FormBuilder,
    private wizardHelperService: WizardHelperService
  ) {
  }

  ngOnInit() {
    this.addControls();
    this.onOpenForm.emit();
    this.postalCodeMask = this.country.value === 2 ? Patterns.postalCode.mask.canada : Patterns.postalCode.mask.usa;
  }

  addControls() {
    this.addressForm = this.formBuilder.group({
      'addressName': ['', Validators.required],
      'address1': ['', Validators.required],
      'address2': [''],
      'postalCode': ['', [Validators.required, Validators.pattern(this.getPatternForPostalCode())]],
      'city': ['', Validators.required],
      'state': ['', Validators.required],
      'countryId': [''],
      'addressId': [''],
      'isDefaultBillingAddress': [this.typeOfAddress === 'billing'],
      'isDefaultShippingAddress': [this.typeOfAddress === 'shipping']
    });
    this.setAddressDetails();
  }

  getPatternForPostalCode(): RegExp {
    return this.country.value === 2 ? Patterns.postalCode.pattern.canada : Patterns.postalCode.pattern.usa;
  }

  onSubmit() {
    this.notifications = [];
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    const body = JSON.parse(JSON.stringify(this.addressForm.value));
    body['countryId'] = this.country.value;
    this.addressService.updateAddressList(body).subscribe(response => {
      this.wizardHelperService.setAddressList(response.addresses);
      // to defined next step in flow
      this.onEndAddEditAddress.emit();
      this.addressForm.reset();
      this.isLoading = false;
      this.spinnerText = null;
    }, error => {
      this.notifications.push({severity: 'error', summary: error.json().error_description});
      this.isLoading = false;
      this.spinnerText = null;
    });
  }

  /**
   * Finish editing or editing address process
   */
  endAddEditAddress() {
    this.onEndAddEditAddress.emit();
  }

  // Fill out the form to editing address
  setAddressDetails() {
    this.addressForm.controls['countryId'].setValue(this.country.displayName);
    if (this.currentAddress) {
      for (const key in this.currentAddress) {
        if (key !== 'countryId') {
          this.addressForm.controls[key].setValue(this.currentAddress[key]);
        }
      }
    }
  }
}
