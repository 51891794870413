import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AffiliateService} from '../../../services/affiliate.service';
import {Message} from 'primeng/primeng';
import {AffiliateMessage} from '../../../classes/affiliate-message';

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.css']
})
export class MessageCenterComponent implements OnInit {
  @Input() panelCollapsed: boolean;
  isLoading: false;
  messages: AffiliateMessage[];
  notifications: Message[] = [];

  constructor(private affiliateService: AffiliateService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.getMessages();
  }

  // Get messages to displaing in message-center-block from API using service
  getMessages() {
    this.affiliateService.getMessages().subscribe(response => {
      this.messages = response.messages;
    }, error => {
      this.notifications.push({severity: 'error', summary: error.json().error_description});
    });
  }
}
