export class Country {
  displayName: string;
  states: State[];
  value: number;
}

export class State {
  displayName: string;
  value: number;
}
