import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AddressService} from '../../../services/address.service';
import {ConfigService} from '../../../services/config.service';
import {GoogleAnalyticsService} from '../../../services/google-analytics.service';
import {LoginService} from '../../../services/login.service';
import {UserService} from '../../../services/user.service';
import {Message} from 'primeng/primeng';
import {showError} from '../../../helpers/form-helper';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  isCheckingSsoIframe = false;
  iframeOrigin: string;
  iframeSrc: SafeResourceUrl;
  iframeSrcLoginForm: SafeResourceUrl;
  isLoading = false;
  loginForm: FormGroup;
  notifications: Message[] = [];
  showError: Function = showError;
  spinnerText: string;
  submitErrorDescription: string;
  submitted = false;
  visibleLoginFormIframe = false;

  /**
   * Handle message event from child iframes
   * @param event
   */
  @HostListener('window:message', ['$event'])
  onWindowMessage(event) {
    if (event.origin === this.iframeOrigin) {
      this.handleGotUserData(event);
    }
  }

  constructor(
    private fb: FormBuilder,
    private addressService: AddressService,
    private configService: ConfigService,
    private gaService: GoogleAnalyticsService,
    private loginService: LoginService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {
    this.iframeOrigin = this.configService.getConfiguration().ssoDomain;
  }

  ngOnInit() {
    this.checkUserIsLogged();
    this.createIframeSrc();
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  checkUserIsLogged(): void {
    this.spinnerText = 'Processing your request...';
    this.isLoading = true;
    // const currentUser = this.userService.getCurrentUser();
    // if (!currentUser) {
      // Check token in Single Sign On
      this.isCheckingSsoIframe = true;
      // Timeout is needed for carousel takes full width of parent
      setTimeout(() => {
        if (this.isCheckingSsoIframe) {
          this.visibleLoginFormIframe = true;
          this.isLoading = false;
        }
      }, 3000);
    // }
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  createIframeSrc(): void {
    this.iframeSrcLoginForm = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeOrigin + `/Auth/Login?memberId=&returnUrl=${location.origin}`
    );
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeOrigin + `/auth/loggedin?parentURL=${location.origin}`
    );
  }

  /**
   * Get event data
   * @param messageEvent
   */
  private getEventData(messageEvent: MessageEvent): any {
    try {
      return JSON.parse(messageEvent.data);
    } catch (e) {
      return messageEvent.data;
    }
  }

  /**
   * Send entered email to login service
   */
  setEnteredEmail(email: string) {
    this.loginService.setEnteredEmail(email);
  }

  onForgotPasswordClick(): void {
    this.gaService.sendGoogleAnalyticsEvent('forgotPassword');
  }

  // get billing and shipping address of current user to saving
  getAdditionalUserDataAndRedirect(userInfo): void {
    const currentUser = JSON.parse(JSON.stringify(userInfo));
    this.userService.setCurrentUser(currentUser);
    this.isLoading = false;
    this.spinnerText = null;
    this.router.navigate(['/main']);
  }

  /**
   * Send username and password to login service
   */
  onSubmit(): void {
    this.submitted = true;
    if (this.loginForm.valid && this.loginForm.dirty) {
      this.isLoading = true;
      this.spinnerText = 'Processing your request...';
      this.loginService.login(this.loginForm.value)
        .subscribe(user => {
          this.isLoading = false;
          this.spinnerText = null;
          this.router.navigate(['/main']);
        }, error => {
          this.isLoading = false;
          this.spinnerText = null;
          this.submitErrorDescription = error.json().error_description;
        });
    }
  }

  private handleGotUserData(messageEvent: MessageEvent): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    const eventData = this.getEventData(messageEvent);

    if (typeof eventData === 'string') { // eventData is token
      this.isCheckingSsoIframe = false;
      this.userService.getCurrentUserByToken(eventData).subscribe(response => {
        this.getAdditionalUserDataAndRedirect(response);
      }, error => {
        if (error.status === 401 && error.statusText === 'Unauthorized') {
          this.visibleLoginFormIframe = true;
          this.isLoading = false;
          return;
        }
        this.notifications.push({severity: 'error', summary: error.json().Message});
        this.isLoading = false;
        this.spinnerText = null;
      });
    } else if (eventData.memberId) { // eventData is user info object
      this.isCheckingSsoIframe = false;
      this.getAdditionalUserDataAndRedirect(eventData);
    } else {
      this.isLoading = false;
      this.visibleLoginFormIframe = true;
    }
  }
}
